import React, {Suspense, lazy} from "react";
import {Container} from "react-bootstrap";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy/PrivacyPolicy"));

const PrivacyPolicies = () => {
  return (
    <Container>
      <MetaData title="Privacy policy" />
      <SEO title="Privacy policy" />
      <Suspense fallback={<h1>{/*blank */}</h1>}>
        <PrivacyPolicy />
      </Suspense>
    </Container>
  );
};

export default PrivacyPolicies;
