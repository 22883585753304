import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {SuresProvider} from "./context";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <SuresProvider>
    <Router>
      {/* <React.StrictMode> */}
      <App />
      {/*  </React.StrictMode> */}
    </Router>
  </SuresProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
