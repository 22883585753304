import * as React from "react";
import {Container} from "react-bootstrap";

import ButtonLabel from "./ButtonLabel";

import ShortTermContent from "./ShortTermContent";

import "./ChooseInsurance.scss";

const ChooseInsurance = ({
  onClick,
  displayContents,
  props,
  dynamicClass,
  insuranceTypes,
  legalCoverBenefits,
}) => {
  return (
    <div className="chooseInsuranceSection">
      <Container fluid>
        <h4 className="chooseInsuranceHeader">
          Select the short-term insurance cover that you need below, insert your <br />
          details, and you will be contacted with a quote to suit you.
        </h4>
        <div>
          <ButtonLabel
            onClick={onClick}
            dynamicClass={dynamicClass}
            insuranceTypes={insuranceTypes}
          />
        </div>
      </Container>
      <ShortTermContent
        displayContents={displayContents}
        insuranceTypes={insuranceTypes}
        legalCoverBenefits={legalCoverBenefits}
      />
    </div>
  );
};

export default ChooseInsurance;
