import * as React from "react";

import Buttons from "../../Buttons";

import "./ButtonLabel.scss";

const ButtonLabel = ({props, onClick, dynamicClass, insuranceTypes}) => {
  return (
    <div className="btnLabelSection">
      {insuranceTypes.map(btn => {
        const {id, buttonTitle, buttonIcon} = btn.button;

        return (
          <Buttons
            type="button"
            key={buttonTitle}
            customStyle={`${"shrtTermBtnStyle"}
               ${
                 dynamicClass === id
                   ? `${"dynamicClassPearl"}`
                   : `${"notDynamicClassPurple"}`
               }`}
            onClick={() => onClick(id)}
          >
            <img
              src={buttonIcon.url}
              alt={buttonTitle}
              className={`${buttonIcon.className} first`}
            />
            <img
              src={buttonIcon.urlTwo}
              alt={buttonTitle}
              className={`${buttonIcon.className} second`}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            {buttonTitle}
          </Buttons>
        );
      })}
    </div>
  );
};

export default ButtonLabel;
