import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Funerals from "./pages/Funerals";
import CreditLifes from "./pages/CreditLifes";
import SmartWills from "./pages/SmartWills";
import ShortTermInsurances from "./pages/ShortTermInsurances";
import About from "./pages/About";
import News from "./pages/News";
import SingleNews from "./pages/SingleNewsPage";
import Faq from "./pages/FAQ";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import Commuter from "./pages/Commuter";
import Error from "./pages/Error";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/products/commuter" component={Commuter} />
        <Route exact path="/products/funeral" component={Funerals} />
        <Route exact path="/products/credit-life" component={CreditLifes} />
        <Route exact path="/products/smart-will" component={SmartWills} />
        <Route
          exact
          path="/products/short-term-insurance"
          component={ShortTermInsurances}
        />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:id" render={props => <SingleNews {...props} />} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/privacy-policy" component={PrivacyPolicies} />
        <Route component={Error} />
      </Switch>
    );
  }
}

export default Routes;
