import * as React from "react";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import Buttons from "../Buttons";

import "./FlexibleOptions.scss";

const FlexibleOptions = () => {
  return (
    <Row className="flexSection">
      <Col md={10} sm={12}>
        <p className="flexText">
          Flexible payout options to help you through tough times. Benefits paid up to 6
          months.
        </p>
      </Col>
      <Col className="alignButton">
        <Link to="/products/funeral/#flexiblePayout">
          <Buttons type="button" customStyle="flexibleLearnMore" title="Learn More" />
        </Link>
      </Col>
    </Row>
  );
};

export default FlexibleOptions;
