import React, {useState} from "react";
import {Tabs, Tab} from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import FAQAccordion from "./FAQAccordion";

import "./FAQTabs.scss";

import {funeralQuestions, creditLifeQuestions} from "../../constants/FAQ";

const FAQTabs = () => {
  const [key, setKey] = useState("funeral");

  return (
    <div className="faqTabsSection">
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
        <Tab eventKey="funeral" title="Funeral" className="funeralPad">
          {funeralQuestions.map(funeralQuestion => {
            const {id, title, questions} = funeralQuestion;

            return (
              <div key={id}>
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton className="accordionTitle">
                        {title}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <hr />
                    <AccordionItemPanel>
                      {questions.map((question, index) => {
                        return (
                          <div key={index}>
                            <FAQAccordion
                              subTitle={question.subTitle}
                              text={question.text}
                            />
                          </div>
                        );
                      })}
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            );
          })}
        </Tab>
        <Tab eventKey="creditLife" title="Credit Life">
          {creditLifeQuestions.map(creditLifeQuestion => {
            const {id, questions} = creditLifeQuestion;

            return (
              <div key={id}>
                {questions.map((question, index) => {
                  return (
                    <div key={index}>
                      <FAQAccordion subTitle={question.subTitle} text={question.text} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Tab>
      </Tabs>
    </div>
  );
};

export default FAQTabs;
