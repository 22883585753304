import moment from "moment";
import _ from "lodash";

export const minMaxVal = values => {
  let newMinVal = _.minBy(values, "value");
  let newMaxVal = _.maxBy(values, "value");

  return {
    newMinVal,
    newMaxVal,
  };
};

export const formatDate = date => {
  return moment(date).format(`D MMMM, YYYY`);
};

export const filterInput = event => {
  event.preventDefault();
  const filteredInput = event.target.value.replace(/[abAB$&()-_.*]|\d+/g, "");
  return filteredInput;
};

export const mappingArticlesWithImages = response => {
  const artitleMapper = response.data.items.map(article => article.fields);
  const assets = response.data.includes.Asset;
  let articleList = artitleMapper.map(article => {
    const articleImgs = assets.find(
      articleImg => articleImg.sys.id === article.articleImg.sys.id
    );
    const icons = assets.find(icon => icon.sys.id === article.icon.sys.id);
    const previewImages = assets.find(
      previewImage => previewImage.sys.id === article.previewImage.sys.id
    );

    article.articleImgUrl = articleImgs ? articleImgs.fields.file.url : null;
    article.iconUrl = icons ? icons.fields.file.url : null;
    article.previewImageUrl = previewImages ? previewImages.fields.file.url : null;

    return article;
  });

  const myData = [].concat(articleList).sort((a, b) => (a.date < b.date ? 1 : -1));

  let latestData = myData;

  latestData.forEach((element, index) => {
    myData[index].date = formatDate(element.date);
  });

  let latestArticles = latestData;

  return {
    latestArticles,
    articleList,
    assets,
  };
};

export const isMainMember = (mainMemberName, mainMemberAge) => {
  if (mainMemberAge.length >= 1 || mainMemberName.length >= 1) {
    return true;
  }
};

export const mapParents = parents => parents.map(parent => parent.name);

export const mapChildren = children => children.map(child => child.name);

export const mapSpouses = spouses => spouses.map(spouse => spouse.name);

export const filterChildren = children => children.filter(child => child.name !== "");
export const filterSpouse = spouses => spouses.filter(spouse => spouse.name !== "");
export const filterParents = parents => parents.filter(parent => parent.name !== "");

export const getParentNameLength = parents =>
  parents.map(({name}, id) => {
    let mapParents = parents[id].name.length;
    return mapParents;
  });

export const getStatusForChildAndSpouse = (children, spouses) => {
  let childAndSpouse = false;
  let child = filterChildren(children).length;
  let spouse = filterSpouse(spouses).length;

  if (child > 0 && spouse > 0) {
    childAndSpouse = true;
  }
  return childAndSpouse;
};

export const getStatusForChildAndParent = (children, parents) => {
  let childAndParent = false;
  let child = filterChildren(children).length;

  if (child <= 0) {
    childAndParent = true;
  }
  return childAndParent;
};

export const getStatusForSpouseAndParent = (spouses, parents) => {
  let spouseAndParent = false;
  let spouse = filterSpouse(spouses).length;

  if (spouse <= 0) {
    spouseAndParent = true;
  }
  return spouseAndParent;
};
