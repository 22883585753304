import * as React from "react";
import {Row, Col} from "react-bootstrap";

import "./Paperless.scss";

const Paperless = () => {
  return (
    <Row>
      <Col className="paperlessSection">
        <h3 className="paperlessTitle">
          Due to our paperless system, <br />
          getting a Fin Cover Funeral Plan is effortless.
        </h3>
        <h6 className="paperlessSubtitle">
          NiftyCover is an Authorised Financial Services Provider in terms of FAIS. Our
          Fin Cover Funeral & Commuter products are designed to protect you and your loved
          ones against the possible unforeseen circumstances of life.
        </h6>
        <p className="paperlessText">
          Our process is highly efficient, giving you a cost estimate almost instantly and
          the full application can be completed in minutes, all online. We understand that
          everyone is different which is why we have designed a unique system that allows
          you to build the funeral cover product you need for your family, and most
          importantly for your pocket.
        </p>
      </Col>
    </Row>
  );
};

export default Paperless;
