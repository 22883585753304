export default [
  {
    site: {
      siteMetadata: {
        title: "A new way of insurance | niftycover",
        description:
          "At Fin Cover we understand that life is unpredictable and the best thing we can do for you and the well-being of your family is to prepare you; ensuring that your estate, your family and your assets are taken care of; through a product offering that will cover all aspect of your life.",
        keywords:
          "niftycover, Niftycover, Funeral cover, Funeral plans, Life cover, Funeral insurance, Life insurance, Funeral plan, funeral policy, Life policy, Funeral cover quote, Free funeral plan quote, Cost of funeral cover, Family funeral plan, Insurance claim, Family insurance, Life cover application, Funeral plan application, How to plan a funeral, Affordable funeral cover, Death cover, Death insurance, What is funeral cover, What is a funeral plan, What funeral cover do I need, What does a funeral cost, Funeral cover benefits, Difference between funeral and life insurance",
        siteUrl: "https://www.cover.fin.africa",
        author: "marvine@smartadvance.com",
        siteName: "cover.fin.africa",
      },
    },
  },
];
