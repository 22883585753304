import React, {Suspense, lazy} from "react";
import {Container} from "react-bootstrap";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";
import Banner from "../components/Global/Banner";

import "./index.scss";

import NewsBanner from "../assets/images/newsBanner.jpg";

const NewsComponent = lazy(() => import("../components/News"));

const News = () => {
  return (
    <div>
      <MetaData title="News" />
      <SEO title="News" />
      <Banner
        bgImage={NewsBanner}
        firstText="Keep up to date"
        secondText="with the latest news"
        customClass="newsBannerStyle"
      ></Banner>
      <Container>
        <Suspense fallback={<h1>{/*blank */}</h1>}>
          <NewsComponent />
        </Suspense>
      </Container>
    </div>
  );
};

export default News;
