import * as React from "react";
import NumericInput from "react-numeric-input";

import "./SelectAmount.scss";

import {minMaxVal, filterInput} from "../../utils";

const funeralCoverLevels = [
  {value: 50000.0},
  {value: 40000.0},
  {value: 30000.0},
  {value: 20000.0},
  {value: 10000.0},
  {value: 5000.0},
];

const commuterCoverLevels = [{value: 30000.0}, {value: 20000.0}, {value: 10000.0}];

class SelectAmount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.productId === 3 ? funeralCoverLevels : commuterCoverLevels,
    };

    this.props.currentAmount(
      this.props.productId === 3
        ? funeralCoverLevels[4].value
        : commuterCoverLevels[2].value
    );
  }

  parse = num => num.replace("R", "");

  myFormat = num => {
    return `${"R "}` + num;
  };

  disableKey8(e) {
    e.preventDefault();
    window.addEventListener("keydown", function(event) {
      // if the keyCode is 8(backspace, disable it )
      if (event.keyCode === 8) {
        event.preventDefault();
        return false;
      }
    });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  getCurrentVal = async e => {
    this.props.currentAmount(e);
  };

  render() {
    let values = this.state.value;

    const {newMinVal, newMaxVal} = minMaxVal(values);

    const minVal = newMinVal.value;
    const maxVal = newMaxVal.value;

    return (
      <div className={`${"selectSection"} ${this.props.focusAmount}`}>
        <NumericInput
          name="whatever"
          className="form-control"
          value={
            this.props.productId === 3
              ? funeralCoverLevels[4].value
              : commuterCoverLevels[2].value
          }
          min={minVal}
          max={maxVal}
          step={(component, direction) => {
            return component.state.value < 10000 ? 5000 : 10000;
            // return direction === NumericInput.DIRECTION_UP ? 10000 : 5000;
            // return window.outerWidth % 100 + 1;
          }}
          precision={0}
          size={5}
          maxLength={6}
          parse={this.parse}
          format={this.myFormat}
          onChange={this.getCurrentVal}
          onKeyDown={e => this.disableKey8(e)}
          onKeyPress={e => {
            filterInput(e);
            this.setState({value: filterInput});
            console.log(this.state.value, "clicked");
          }}
        />
      </div>
    );
  }
}

export default SelectAmount;
