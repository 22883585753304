import React, {Suspense, lazy} from "react";
import {Row, Col, Container} from "react-bootstrap";

import Buttons from "../../Buttons";

import "./ShortTermContent.scss";

const GetQuoteModal = lazy(() => import("../../QuoteModal"));
const LegalAdviceBlock = lazy(() => import("./LegalAdviceBlock"));
const VehicleInsurance = lazy(() => import("./VehicleInsurance"));

class ShortTermContent extends React.Component {
  state = {
    show: false,
  };

  handleClose = () => {
    this.setState({show: false});
  };

  handleShow = id => {
    const displayContents = this.props.displayContents;

    if (displayContents === id) {
      this.setState({
        show: true,
        displayContents: id,
      });
    }
  };

  render() {
    const {displayContents, insuranceTypes, legalCoverBenefits} = {...this.props};

    const {show} = {...this.state};

    return (
      <>
        <div
          className={
            displayContents !== "VehicleInsurance"
              ? `${"shortTermContentSection"}`
              : `${"shortTermSectionVehicle"}`
          }
        >
          <Container fluid>
            {insuranceTypes.map(type => {
              const {
                benefits,
                text,
                id,
                title,
                smallText,
                image: {url, alt},
                partnerLogoFooter: {
                  logoId,
                  logo: {logoImg, logoText},
                },
                rightSideData,
                partnerRefId,
              } = type;

              return (
                <div key={id}>
                  {displayContents === id ? (
                    <Row>
                      <Col md={3}>
                        <div className="shortTermContentRectangle" key={id}>
                          <img src={url} alt={alt} className="shortTermImg" />
                        </div>
                      </Col>
                      <Col md={displayContents !== "VehicleInsurance" ? 9 : 4}>
                        <div className="shortTermRight" key={id}>
                          <h4 className="shortTermTitle">{title}</h4>
                          <p className="shortTermText">{text}</p>
                          <p className="shortTermSmallText">{smallText}</p>
                          {benefits && benefits ? (
                            <ul className="benefits" key={benefits.id}>
                              {benefits.map((benefit, index) => {
                                return <li key={index}>{benefit.text}</li>;
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                          <Buttons
                            type="button"
                            title="I'm Interested"
                            customStyle="shortInsBtn"
                            onClick={() => this.handleShow(id)}
                          />
                          <Suspense fallback={<h1>{/* blank */}</h1>}>
                            <GetQuoteModal
                              show={show}
                              partnerRefId={partnerRefId}
                              handleClose={() => this.handleClose()}
                            />
                          </Suspense>
                        </div>
                        <div className="bottomCorner">
                          <div className="verticalLine"></div>
                          <div className="logoBottom">
                            <img
                              src={logoImg}
                              alt={logoImg}
                              style={
                                logoId === "miwayLead"
                                  ? miwayLead
                                  : logoId === "legalExLead"
                                  ? legalExLead
                                  : logoId === "onePlanLead"
                                  ? onePlanLead
                                  : ""
                              }
                            />
                            <p>{logoText}</p>
                          </div>
                        </div>
                      </Col>
                      <Suspense fallback={<h1>{/*Blank*/}</h1>}>
                        <VehicleInsurance
                          displayContents={displayContents}
                          rightSideData={rightSideData}
                        />
                      </Suspense>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </Container>
        </div>
        {displayContents === "LegalCover" ? (
          <Suspense fallback={<h1>{/*Blank*/}</h1>}>
            <Container style={{padding: `1em 2em`}}>
              <LegalAdviceBlock
                legalCoverBenefits={legalCoverBenefits}
                handleShow={() => this.handleShow("LegalCover")}
                handleClose={() => this.handleClose()}
              />
            </Container>
          </Suspense>
        ) : (
          ""
        )}
      </>
    );
  }
}

//HACK: CSS refused to apply on the vehicle insurance tab hence the need for this
const miwayLead = {
  width: `24%`,
  height: `24%`,
  paddingLeft: `0em`,
};

const legalExLead = {
  width: `35%`,
  marginTop: `0em`,
  paddingLeft: `0em`,
};

const onePlanLead = {
  width: `26%`,
  marginTop: `0em`,
  paddingLeft: `0em`,
};

export default ShortTermContent;
