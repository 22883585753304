import React from "react";
import {Row, Col} from "react-bootstrap";

import Title from "../../Title";

import "./FuneralFeatures.scss";

import PolicyFeatures from "../../../constants/policyFeatures";

const FuneralFeatures = () => {
  const policyFeatures = PolicyFeatures;

  return (
    <div>
      <Row>
        <Col>
          <Title
            header="Features of the Fin Cover Funeral Policy"
            customStyle={{
              textAlign: `center`,
              paddingBottom: `1em`,
            }}
          />
        </Col>
      </Row>
      <Row className="featuresRow">
        {policyFeatures.map((features, index) => {
          const {image, divClass, text, smallText} = features;
          return (
            <Col key={index}>
              <div className={divClass}>
                <img src={image.url} alt={image.alt} className={image.class} />
                <p className="policyText">{text && text}</p>
                <p className="policySmallText">{smallText && smallText}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default FuneralFeatures;
