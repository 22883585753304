import * as React from "react";

import Title from "../../Title";

import "./ContactDetails.scss";

import Location from "../../../assets/images/location.svg";
import Mobile from "../../../assets/images/mobile.svg";
import Mail from "../../../assets/images/mail.svg";
import Facebook from "../../../assets/images/facebook.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Instagram from "../../../assets/images/instagram.svg";
import Twitter from "../../../assets/images/twitter.svg";

import config from "../../../constants/configs";

class ContactDetails extends React.Component {
  render() {
    const {
      NIFTYCOVER_CONTACT_EMAIL,
      LINKEDIN_URL,
      FACEBOOK_URL,
      TWITTER_URL,
      INSTAGRAM_URL,
      NIFTYCOVER_CONTACT_NUMBER,
    } = config;

    return (
      <div className="addy">
        <Title
          header="Contact Details"
          customStyle={{
            textAlign: `left`,
            paddingLeft: `0em`,
            fontSize: `1.4rem`,
            color: `var(--mainWhite)`,
          }}
        />
        <span className="locationDisplay">
          <img src={Location} alt="location" className="locationIcon" />
          <p className="location">
            Office G003
            <br />
            Podium at Menlyn
            <br />
            43 Ingersol Road
            <br />
            Lynnwood Glen
            <br />
            0145
          </p>
        </span>
        <div>
          <a
            href={`${"tel:"}${NIFTYCOVER_CONTACT_NUMBER}`}
            title={NIFTYCOVER_CONTACT_NUMBER}
            className="mobileLink"
          >
            <img src={Mobile} alt="mobile" className="mobileIcon" />
            {NIFTYCOVER_CONTACT_NUMBER}
          </a>
        </div>
        <div className="mail">
          <a
            href={`${"mailto:"}${NIFTYCOVER_CONTACT_EMAIL}`}
            title={NIFTYCOVER_CONTACT_EMAIL}
            className="mailLink"
          >
            <img src={Mail} alt="mail" className="mailIcon" />
            {NIFTYCOVER_CONTACT_EMAIL}
          </a>
        </div>
        <br />
        <br />
        <div className="contactSocMedia">
          <p>Follow us on Social Media</p>
          <a href={LINKEDIN_URL}>
            <img src={LinkedIn} alt="linkedin logo" className="contactLI" />
          </a>
          &nbsp;&nbsp;
          <a href={FACEBOOK_URL}>
            <img src={Facebook} alt="facebook logo" className="contactFB" />
          </a>
          &nbsp;&nbsp;
          <a href={TWITTER_URL}>
            <img src={Twitter} alt="twitter logo" className="contactTW" />
          </a>
          &nbsp;&nbsp;
          <a href={INSTAGRAM_URL}>
            <img src={Instagram} alt="instagram logo" className="contactIN" />
          </a>
        </div>
      </div>
    );
  }
}

export default ContactDetails;
