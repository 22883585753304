import React from "react";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import {Event} from "../Tracking";
import Buttons from "../Buttons";

import "./CostEffective.scss";

import AccessIcon from "../../assets/images/accessIcon.svg";
import CostEffectiveIcon from "../../assets/images/costEffective.svg";

const CostEffective = () => {
  return (
    <Row>
      <Col md={1}>
        <div className="sideImages">
          <div className="costEffectiveBox">
            <img
              src={CostEffectiveIcon}
              alt="cost effective"
              className="costeffectiveImg"
            />
          </div>
          <div className="accessBox">
            <img src={AccessIcon} alt="24/7 access" className="accessImg" />
          </div>
        </div>
      </Col>
      <Col md={11} sm={12} className="costSection">
        <div className="costBlock">
          <h6 className="costHeader">Cost effective</h6>
          <p className="costText">
            Dealing with you directly online means we do not pay exorbitant commissions to
            brokers or use expensive call centers, passing the substantial cost savings on
            to you through low monthly payments.
          </p>
        </div>
        <div className="costBlockTwo">
          <h6 className="costHeader">24/7 Access</h6>
          <p className="costText">
            In addition, all purchases, alterations, queries and claims are available to
            you 24 hours a day, 7 days a week, 365 days a year; wherever you may be, from
            your laptop or smartphone. Convenience at its best!
          </p>
        </div>
        <div className="costAlignBtn">
          <Link to="/products/funeral/#getQuote">
            <Buttons
              type="button"
              customStyle="costGetQuote"
              title="Get Quote"
              onClick={() => Event("GET_QUOTE", "About", "ABOUT_PAGE")}
            />
          </Link>
          &nbsp; &nbsp;
          <Link to="/products/funeral">
            <Buttons
              type="button"
              customStyle="costLearnMore"
              title="Learn More"
              onClick={() => Event("LEARN_MORE_BTN", "About", "ABOUT_PAGE")}
            />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default CostEffective;
