import * as React from "react";
import ReactGA from "react-ga";

import Title from "../../Title";
import Buttons from "../../Buttons";
import WillBlock from "./WillBlock";

import "./SmartWill.scss";

import config from "../../../constants/configs";

const SmartWill = () => {
  return (
    <div className="smartWillSection">
      <Title header="Partnership with SmartWill" />
      <h6 className="smartWillHeader">Estate planning made easy for you</h6>
      <p className="smartWillText">
        As a value added service to you, and to ensure that you are fully covered in all
        regards, We have partnered with SmartWill so all your assets are allocated
        according to your wishes in the unfortunate event of your death. A will stipulates
        how your assets and possessions such as property, money and other assets will be
        distributed, and who they will be distributed to when you pass away. Having a will
        gives you peace of mind that your estate will be taken care of after you are gone.
      </p>
      <WillBlock />
      <h4 className="smartWillHeaderTwo">How do I get a will?</h4>
      <div className="smartWillTextTwo">
        <p>
          SmartWill will calculate the cost of winding up your estate, identify gaps in
          your estate planning and suggest fixes.
          <br />
          It’s accessible 24/7 making it easy to create and update a will.
          <br />A professional executor to stream-line the finalization of your estate.
        </p>
      </div>
      <div className="smartWillLetsGo">
        <Title
          header="Get your free Will to the value of R500 now."
          customStyle={{
            color: `var(--mainWhite)`,
          }}
        />
        <p className="smartWillLetsGoText">
          Click here and use the promo code Fin Cover when you sign up to redeem your
          discounted will.
        </p>
        <ReactGA.OutboundLink
          eventLabel="smart will button in smart will page"
          to={config.SMART_WILL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Buttons type="button" title="Let’s go" customStyle="smartBtn" />
        </ReactGA.OutboundLink>
      </div>
    </div>
  );
};

export default SmartWill;
