import * as React from "react";
import {Row, Col} from "react-bootstrap";
import Title from "../Title";
import "./ChooseCover.scss";

const ChooseCover = props => {
  const [cover, setCover] = React.useState(
    window.location.pathname === "/products/commuter" ? "commuter" : "funeral"
  );

  React.useEffect(() => {
    props.sendProductId(cover);
  }, [props, cover]);

  return (
    <Row>
      <Col>
        <Title
          header="Get a free online quote."
          customStyle={{
            marginTop: `-1em`,
          }}
        />
        <div className="coverDisplay">
          <p>Choose your option</p>
          <label className="chooseLabelContainer">
            Funeral Cover
            <input
              type="radio"
              name="funeral"
              value="funeral"
              checked={cover === "funeral"}
              onChange={e => setCover(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="chooseLabelContainer">
            Commuter Cover
            <input
              type="radio"
              name="commuter"
              value="commuter"
              checked={cover === "commuter"}
              onChange={e => setCover(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </Col>
    </Row>
  );
};

export default ChooseCover;
