import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";

import Logo from "../assets/images/logo.svg";
import siteMetaData from "../constants/siteMetaData.js";

export async function getStaticProps(props) {
  const res = await props;

  if (!res) {
    return {
      notFound: true,
    };
  }

  return {
    props: props,
  };
}

const MetaData = props => {
  getStaticProps(props);

  const {description, image, title, imageFormat} = props;
  const {site} = siteMetaData[0];

  const metaDescription = description || site.siteMetadata.description;

  const metaUrl = site.siteMetadata.siteUrl;

  const metaImage =
    typeof image === "undefined" ? `${metaUrl}${Logo}` : `${"https://"}${image}`;

  const imageType = imageFormat === "jpg" ? "image/jpeg" : "image/png";

  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="initial-scale=1.0,
        width=device-width"
      />
      <meta property="description" content={metaDescription} />
      <meta property="keywords" content={siteMetaData[0].site.siteMetadata.keywords} />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:type" content={imageType} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:site_name" content={site.siteMetadata.siteName} />
      <meta property="og:image:secure_url" content={site.siteMetadata.siteName} />
      <meta property="twitter:image:alt" content={metaImage} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content={site.siteMetadata.author} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:image" content={metaImage} />
      <meta property="twitter:description" content={metaDescription} />
    </Head>
  );
};

MetaData.defaultProps = {
  description: ``,
};

MetaData.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default MetaData;
