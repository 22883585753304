import React from "react";
import Routes from "./Routes";
import dotenv from "dotenv";
import {PageView, initGA} from "./components/Tracking";
import Header from "./components/Global/Header";
import Footer from "./components/Global/Footer/Footer";
import Contact from "./components/Global/Contact/Contact";

import "./App.css";
import "./assets/fonts/intro/font.scss";

import config from "./constants/configs";

dotenv.config();

class App extends React.Component {
  componentDidMount = () => {
    initGA(config.GOOGLE_ANALYTICS);
    PageView();
  };

  render() {
    return (
      <div>
        <Header />
        <Routes />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;
