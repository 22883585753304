import * as React from "react";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import Buttons from "../Buttons";
import Funeral from "../../assets/images/homepage-funeral-icon.svg";
import Commuter from "../../assets/images/homepage-commuter-icon.svg";

import "./CoverPlanBlock.scss";

const CoverPlanBlock = () => {
  return (
    <div className="coverBlockSection">
      <Row className="coverRow">
        <Col>
          <div className="coverBlock">
            <img src={Funeral} alt="funeral" />
            <h4 className="coverBlockHeader">Funeral cover</h4>
            <p className="coverBlockText">
              The Fin Cover Funeral Policy allows you to cover yourself, your spouse, up
              to 5 children, as well as your parents and parents-in-law. Claims are paid
              within 48 hours after receipt of the minimum requirements and paid straight
              into the beneficiary’s bank account so that you can rest assured all
              arrangements can be taken care of.
            </p>
            <div className="coverButton">
              <Link to="/products/funeral">
                <Buttons
                  type="button"
                  customStyle="coverLearnMoreBtn"
                  title="Learn More"
                />
              </Link>
            </div>
          </div>
        </Col>
        <Col>
          <div className="coverBlock">
            <img src={Commuter} alt="commuter" />
            <h4 className="coverBlockHeader">Commuter cover</h4>
            <p className="coverBlockText">
              Having commuter cover will prepare you financially should you die or become
              disabled from a commuting accident whether you are a passenger or
              pedestrian. From as little as R7.50 a month, you can rest easy knowing you
              are fully prepared.
            </p>
            <div className="coverButton">
              <Link to="/products/commuter">
                <Buttons
                  type="button"
                  customStyle="coverLearnMoreBtn"
                  title="Learn More"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CoverPlanBlock;
