import React from "react";
import {Container} from "react-bootstrap";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";
import WhyNifty from "../components/About/WhyNifty";
import Paperless from "../components/About/Paperless";
import CostEffective from "../components/About/CostEffective";
import Banner from "../components/Global/Banner";

import AboutBanner from "../assets/images/aboutPageBanner.jpg";

import "./index.scss";

const About = () => {
  return (
    <div>
      <MetaData title="About" />
      <SEO title="About" />
      <Banner
        bgImage={AboutBanner}
        firstText="A new way of doing"
        secondText="insurance"
        customClass="aboutBannerStyle"
      ></Banner>
      <div className="whyNiftySure">
        <Container>
          <WhyNifty />
        </Container>
      </div>
      <Container>
        <Paperless />
        <CostEffective />
      </Container>
    </div>
  );
};

export default About;
