import * as React from "react";
import Title from "../Title";
import FAQTabs from "./FAQTabs";

class Index extends React.Component {
  render() {
    return (
      <div style={{marginBottom: `7em`}}>
        <Title
          header="General Frequently Asked Questions"
          customStyle={{
            marginBottom: `1em`,
          }}
        />
        <FAQTabs />
      </div>
    );
  }
}

export default Index;
