import React from "react";
import {Container} from "react-bootstrap";

import Banner from "../components/Global/Banner";
import Cover from "../components/Products/Commuter/Cover";
import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

import "./index.scss";

import CommuterBanner from "../assets/images/commuter-cover.png";

const Commuter = () => {
  return (
    <div>
      <MetaData title="Commuters Cover" />
      <SEO title="Commuters Cover" />
      <Banner
        bgImage={CommuterBanner}
        firstText="Take care"
        secondText="of you and your family"
        thirdText="in the event of any accidental death or permanent disability"
        customClass="commuterBannerStyle"
      ></Banner>
      <Container>
        <Cover />
      </Container>
    </div>
  );
};

export default Commuter;
