import * as React from "react";
import {Container} from "react-bootstrap";

import CoverQuestions from "./CoverQuestions.js";
import FuneralFeatures from "./FuneralFeatures.js";
import FlexiblePayout from "./FlexiblePayout.js";
import GetQuote from "../../GetQuote/GetQuote";

import "./index.scss";

class Index extends React.Component {
  render() {
    return (
      <div className="funeralSection">
        <div className="containerFluid">
          <Container fluid>
            <CoverQuestions />
          </Container>
        </div>
        <Container>
          <FuneralFeatures />
          <GetQuote
            data-scroll
            id={"getQuote"}
            backgroundStyle="funeral"
            quoteTitle="quoteTitle"
            amountNeeded="amountNeeded"
            focusAmount="focusAmount"
            monthlyPremium="monthlyPremium"
            monthlyPremiumText="monthlyPremiumText"
            mainMember="mainMember"
            addFamilyMembers="addFamilyMembers"
            rightSideFormBlock="rightSideFormBlock"
            rightBlockCheckbox="rightBlockCheckbox"
            rightFuneralCheckbox="rightFuneralCheckbox"
            showCallBackBtn="showCallBackBtn"
          />
        </Container>
        <div className="containerFluid">
          <Container fluid>
            <FlexiblePayout />
          </Container>
        </div>
      </div>
    );
  }
}

export default Index;
