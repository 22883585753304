/*eslint no-useless-concat: "error"*/

import * as React from "react";
import {Button} from "react-bootstrap";

import "./Buttons.scss";

const Buttons = ({title, customStyle, type, onClick, children}) => {
  return (
    <Button type={type} className={`${"button"} ${customStyle}`} onClick={onClick}>
      {title}
      {children}
    </Button>
  );
};

export default Buttons;
