import React from "react";
import Funeral from "../components/Products/Funeral";
import Banner from "../components/Global/Banner";
import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

import FuneralBanner from "../assets/images/funeralBanner.jpg";

const Funerals = () => {
  return (
    <div>
      <MetaData title="Funeral" />
      <SEO title="Funeral" />
      <Banner
        bgImage={FuneralBanner}
        firstText="For as little as"
        secondText="R36 per month,"
        thirdText="you can prepare for the loss of a loved one."
        customClass="aboutBannerStyle"
      ></Banner>
      <Funeral />
    </div>
  );
};

export default Funerals;
