import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "../../assets/styles/fancy-example.css";

const FAQAccordion = ({props, subTitle, text}) => {
  return (
    <div className="accordionStyle">
      <Accordion allowZeroExpanded="true">
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{subTitle}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p dangerouslySetInnerHTML={{__html: text}} />
          </AccordionItemPanel>
        </AccordionItem>
        <hr />
      </Accordion>
    </div>
  );
};

export default FAQAccordion;
