import * as React from "react";
import {Row, Container, Col} from "react-bootstrap";

import ContactForm from "./ContactForm";
import ContactDetails from "./ContactDetails";

import "./Contact.scss";

class Contact extends React.Component {
  render() {
    return (
      <Container className="contactContainer" data-scroll id="contact-us">
        <Row>
          <Col md={9} sm={12}>
            <ContactForm />
          </Col>
          <Col md={3} sm={12} className="detailsBlock">
            <ContactDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;
