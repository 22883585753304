import * as React from "react";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import {Event} from "../Tracking";
import Buttons from "../Buttons";

import "./ChooseNifty.scss";

const ChooseNifty = () => {
  return (
    <div>
      <Row>
        <Col>
          <h6 className="whySummary">
            We understand that life is unpredictable, and the best thing we can do for you
            and the well-being of your family is to prepare you; ensuring that your
            estate, your family and your assets are taken care of; through a product
            offering that will cover all aspect of your life.
          </h6>
          <p className="whyText">
            We also understand that the best products are not necessarily the most
            expensive ones, but have been created to suit the lifestyles and budget of
            each of our customers.
          </p>

          <Link to="/products/short-term-insurance">
            <Buttons
              type="button"
              customStyle="whyLearnMore"
              title="Learn More"
              onClick={() => Event("LEARN_MORE_BTN_TWO", "Why Nifty", "HOME_PAGE")}
            />
          </Link>
          <p className="whyText">
            With Fin Cover Funeral you can build a funeral plan to suit your family, no
            matter how big or small, and through our trusted partners get household
            contents insurance, pet insurance, medical insurance and more to ensure that
            you are covered and prepared in all aspects of life.
          </p>
          <Link to="/products/funeral">
            <Buttons
              type="button"
              customStyle="whyLearnMore"
              title="Learn More"
              onClick={() => Event("LEARN_MORE_BTN_ONE", "Why Nifty", "HOME_PAGE")}
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseNifty;
