import React from "react";
import {Link} from "react-router-dom";

import Banner from "../components/Global/Banner";
import SEO from "../components/SEO";

import "./index.scss";

export default function Error() {
  return (
    <>
      <SEO title="404 | Not found page" />
      <Banner
        bgImage={require("../assets/images/aboutPageBanner.jpg")}
        firstText="404"
        secondText="Oops Looks like you are getting lost"
        customClass="errorStyle"
      >
        <Link to="/" className="errorLink">
          Return Home
        </Link>
      </Banner>
    </>
  );
}
