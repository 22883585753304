import HomeInsurance from "../assets/images/homeInsurance.svg";
import HomeIconDark from "../assets/images/homeIconDark.svg";
import HomeIconWhite from "../assets/images/homeIconWhite.svg";
import VehicleInsurance from "../assets/images/vehicleInsurance.svg";
import VehicleIconDark from "../assets/images/vehicleIconDark.svg";
import VehicleIconWhite from "../assets/images/vehicleIconWhite.svg";
import PetInsurance from "../assets/images/petInsurance.svg";
import PetIconDark from "../assets/images/petIconDark.svg";
import PetIconWhite from "../assets/images/petIconWhite.svg";
import MedicalInsurance from "../assets/images/medicalInsurance.svg";
import MedicalIconDark from "../assets/images/medicalIconDark.svg";
import MedicalIconWhite from "../assets/images/medicalIconWhite.svg";
import LegalCover from "../assets/images/legalCover.svg";
import LegalIconDark from "../assets/images/legalIconDark.svg";
import LegalIconWhite from "../assets/images/legalIconWhite.svg";
import BusinessInsurance from "../assets/images/businessInsurance.svg";
import BusinessIconDark from "../assets/images/businessIconDark.svg";
import BusinessIconWhite from "../assets/images/businessIconWhite.svg";
import Slide4Bg from "../assets/images/slide4.jpg";
import CarInsuranceBg from "../assets/images/carInsurance.jpg";
import PetInsuranceBg from "../assets/images/petInsurance.jpg";
import MedicalInsuranceBg from "../assets/images/medicalInsurance.jpg";
import LegalCoverBg from "../assets/images/legalCover.jpg";
import BusinessInsuranceBg from "../assets/images/businessInsurance.jpg";

import MiWayLogo from "../assets/images/miWayLogoIns.svg";
import OnePlanLogo from "../assets/images/onePlanLogoIns.svg";
import LegalXLogo from "../assets/images/legalXLogoIns.svg";

import {partnerRef} from "./partnerRefEnums";

export const insuranceTypes = [
  {
    id: "HomeInsurance",
    insuranceClass: "homeInsurance",
    image: {
      url: HomeInsurance,
      alt: "home insurance",
    },
    button: {
      id: "HomeInsurance",
      buttonTitle: "Home",
      buttonIcon: {
        url: HomeIconDark,
        urlTwo: HomeIconWhite,
        alt: "home icon",
        className: "btnHome",
      },
    },
    title: "Home Insurance",
    text:
      "If your house burnt down today, could you afford to replace everything (including your wardrobe) tomorrow? If the answer is ‘no’, then you need to insure your household belongings against fire, theft, water, wind and storm damage.",
    bgImage: {
      url: Slide4Bg,
      className: "homeInsurance",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    benefits: [
      {
        id: "benefits1",
        text:
          "Choose the type of insurance cover that suits your needs and your pocket, is simple to understand, without the fine-print",
      },
      {
        id: "benefits2",
        text:
          "Decide your preferred way to interact with us: via our call centre or online: 24/7/365",
      },
      {
        id: "benefits3",
        text: "Affordable premiums",
      },
      {
        id: "benefits4",
        text: "Easy to understand excess structure",
      },
      {
        id: "benefits5",
        text:
          "Award winning customer service & a hassle-free and efficient claims process",
      },
    ],
    interested: "HomeInsurance",
    partnerLogoFooter: {
      logoId: "miwayLead",
      logo: {
        logoImg: MiWayLogo,
        logoText: "MiWay is a licensed insurer & FSP 33970. Ts & Cs apply",
      },
    },
    partnerRefId: partnerRef.MIWAY,
  },
  {
    id: "VehicleInsurance",
    insuranceClass: "vehicleInsurance",
    image: {
      url: VehicleInsurance,
      alt: "vehicle insurance",
    },
    button: {
      id: "VehicleInsurance",
      buttonTitle: "Vehicle",
      buttonIcon: {
        url: VehicleIconDark,
        urlTwo: VehicleIconWhite,
        alt: "vehicle icon",
        className: "btnVehicle",
      },
    },
    title: "Car Insurance",
    text: "Customise your car insurance quote.",
    bgImage: {
      url: CarInsuranceBg,
      className: "carInsurance",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    benefits: [
      {
        id: "benefits6",
        text:
          "Add cover for non-standard sound systems or car accessories to your motor insurance policy.",
      },
      {
        id: "benefits7",
        text:
          "If you choose to add car hire to your insurance policy, and submit a valid claim, you will be provided a hired car in the event that you cannot drive your vehicle.",
      },
      {
        id: "benefits8",
        text: "Cover your car for either private or business use.",
      },
    ],
    rightSideData: [
      {
        id: "rightSideData1",
        title: "Get comprehensive car insurance offers you:",
        benefits: [
          {
            id: "rightSideDataBenefits1",
            text: "Affordable premiums",
          },
          {
            id: "rightSideDataBenefits2",
            text: "An easy-to-understand excess structure",
          },
          {
            id: "rightSideDataBenefits3",
            text: "Great customer service - and the ability to manage your policy online",
          },
          {
            id: "rightSideDataBenefits4",
            text: "A hassle-free claims process",
          },
          {
            id: "rightSideDataBenefits5",
            text: "Free roadside emergency assistance",
          },
          {
            id: "rightSideDataBenefits6",
            text: "Take me home service",
          },
        ],
      },
    ],
    interested: "VehicleInsurance",
    partnerLogoFooter: {
      logoId: "miwayLead",
      logo: {
        logoImg: MiWayLogo,
        logoText: "MiWay is a licensed insurer & FSP 33970. Ts & Cs apply",
      },
    },
    partnerRefId: partnerRef.MIWAY,
  },
  {
    id: "PetInsurance",
    insuranceClass: "petInsurance",
    image: {
      url: PetInsurance,
      alt: "pet insurance",
    },
    button: {
      id: "PetInsurance",
      buttonTitle: "Pet",
      buttonIcon: {
        url: PetIconDark,
        urlTwo: PetIconWhite,
        alt: "pet icon",
        className: "btnPet",
      },
    },
    title: "Pet Insurance",
    text:
      "Provide the best medical care for your fur-babies with pet insurance to cover for accidental injury, illness, and preventative care such as vaccinations, flea control, sterilization and more.",
    bgImage: {
      url: PetInsuranceBg,
      className: "petInsurance",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    interested: "PetInsurance",
    partnerLogoFooter: {
      logoId: "onePlanLead",
      logo: {
        logoImg: OnePlanLogo,
        logoText: "OnePlan Underwriting Managers (PTY) Ltd (FSP43628)",
      },
    },
    partnerRefId: partnerRef.ONEPLAN,
  },
  {
    id: "MedicalInsurance",
    insuranceClass: "medicalInsurance",
    image: {
      url: MedicalInsurance,
      alt: "medical insurance",
    },
    button: {
      id: "MedicalInsurance",
      buttonTitle: "Medical",
      buttonIcon: {
        url: MedicalIconDark,
        urlTwo: MedicalIconWhite,
        alt: "medical icon",
        className: "btnMedical",
      },
    },
    title: "Medical Insurance",
    text:
      "Get health insurance to cover you and your loved ones with affordable and flexible medical cover that pays your day to day healthcare claims before you see the doctor and gives you access to Private Hospitals for Casualty, Illness and Accident Cover*.",
    bgImage: {
      url: MedicalInsuranceBg,
      className: "medicalInsurance",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    smallText: "*Benefits are plan dependant",
    interested: "MedicalInsurance",
    partnerLogoFooter: {
      logoId: "onePlanLead",
      logo: {
        logoImg: OnePlanLogo,
        logoText: "OnePlan Underwriting Managers (PTY) Ltd (FSP43628)",
      },
    },
    partnerRefId: partnerRef.ONEPLAN,
  },
  {
    id: "LegalCover",
    insuranceClass: "legalCover",
    image: {
      url: LegalCover,
      alt: "legal insurance",
    },
    button: {
      id: "LegalCover",
      buttonTitle: "Legal",
      buttonIcon: {
        url: LegalIconDark,
        urlTwo: LegalIconWhite,
        alt: "legal icon",
        className: "btnLegal",
      },
    },
    title: "Legal Cover",
    text:
      "Comprehensive legal service and legal cost insurance for a wide range of Legal matters including civil and criminal matters.",
    bgImage: {
      url: LegalCoverBg,
      className: "legalCover",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    interested: "LegalCover",
    partnerLogoFooter: {
      logoId: "legalExLead",
      logo: {
        logoImg: LegalXLogo,
        logoText: "Legalex (Pty) Ltd (FSP 5277)",
      },
    },
    partnerRefId: partnerRef.LEGALEX,
  },
  {
    id: "BusinessInsurance",
    insuranceClass: "businessInsurance",
    image: {
      url: BusinessInsurance,
      alt: "business insurance",
    },
    button: {
      id: "BusinessInsurance",
      buttonTitle: "Business",
      buttonIcon: {
        url: BusinessIconDark,
        urlTwo: BusinessIconWhite,
        alt: "business icon",
        className: "btnBusiness",
      },
    },
    title: "Business Insurance",
    text:
      "Business Property / Business Vehicle / Business Interruption / Staff Dishonesty / All Risk / Broadform Liability / Personal Accident / MiBusiness Assist",
    bgImage: {
      url: BusinessInsuranceBg,
      className: "businessInsurance",
      firstText: "Fin Cover can assist with",
      secondText: "quick and affordable",
      thirdText: "short-term insurance",
    },
    interested: "BusinessInsurance",
    partnerLogoFooter: {
      logoId: "miwayLead",
      logo: {
        logoImg: MiWayLogo,
        logoText: "MiWay is a licensed insurer & FSP 33970. Ts & Cs apply",
      },
    },
    partnerRefId: partnerRef.MIWAY,
  },
];

export const legalCoverBenefits = [
  {
    id: "legalCoverBenefits1",
    title: "Premium R140",
    smallText: "*Guaranteed for 12 months.",
    text: "Add R30 and include your family and an additional R50,000 cover per year.",
    btnShow: true,
    insuranceClass: "premium",
  },
  {
    id: "legalCoverBenefits10",
    title: "Legal advice and Legal Documentation",
    benefits: [
      {
        id: "benefits11",
        text:
          "24/7 telephonic legal advice with qualified lawyers, no call centre no paralegals",
      },
      {
        id: "benefits12",
        text: "Includes Civil, Criminal and Labour law",
      },
      {
        id: "benefits13",
        text: "Free Unlimited standard legal documentation",
      },
    ],
    btnShow: false,
    insuranceClass: "legalAdvise",
  },
  {
    id: "legalCoverBenefits14",
    title: "Legal representation",
    benefits: [
      {
        id: "benefits15",
        text: "R30,000 legal cost cover per case",
      },
      {
        id: "benefits16",
        text: "R100,000.00 cover per year",
      },
    ],
    btnShow: false,
    insuranceClass: "legalRep",
  },
  {
    id: "legalCoverBenefits17",
    title: "Bail Service",
    benefits: [
      {
        id: "benefits18",
        text: "Up to R5,000 bail cover once a year",
      },
    ],
    btnShow: false,
    insuranceClass: "bailService",
  },
];
