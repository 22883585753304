import React from "react";
import {Container} from "react-bootstrap";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";
import SmartWill from "../components/Products/SmartWill/SmartWill";
import Banner from "../components/Global/Banner";

import "./index.scss";

import BgImg from "../assets/images/slide3.jpg";

const SmartWills = () => {
  return (
    <div>
      <MetaData title="Smart wills" />
      <SEO title="Smart wills" />
      <Banner
        bgImage={BgImg}
        firstText="Ensure that you are"
        secondText="fully covered in all regards"
        // thirdText="with Fin Cover"
        customClass="smartWillBannerStyle"
      ></Banner>
      <Container>
        <SmartWill />
      </Container>
    </div>
  );
};

export default SmartWills;
