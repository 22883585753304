import React from "react";
import {Container} from "react-bootstrap";

import FAQ from "../components/FAQ";
import Banner from "../components/Global/Banner";
import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

import "./index.scss";

import FaqBanner from "../assets/images/faqBanner.jpg";

const Faq = () => {
  return (
    <div>
      <MetaData title="FAQ" />
      <SEO title="FAQ" />
      <Banner
        bgImage={FaqBanner}
        firstText="Have some questions?"
        secondText="We have the answers."
        customClass="faqBannerStyle"
      ></Banner>
      <Container>
        <FAQ />
      </Container>
    </div>
  );
};

export default Faq;
