import * as React from "react";
import {Row, Col} from "react-bootstrap";

import "./WillBlock.scss";

const WillBlock = () => {
  return (
    <div className="willBlockSection">
      <Row className="willRow">
        <Col>
          <div className="willBlockLeft">
            <h4 className="willBlockHeader">Do I need a Will?</h4>
            <p className="willBlockText">
              The answer is yes, everyone needs a Will, especially if you are:
            </p>
            <ul className="willBlockList">
              <li>Elderly or you know you are ill </li>
              <li>Married or divorced </li>
              <li>Have children </li>
              <li>Own Property or significant Assets </li>
              <li>Have a Trust or Off-shore Assets</li>
            </ul>
          </div>
        </Col>
        <Col>
          <div className="willBlockRight">
            <h4 className="willBlockHeader">What does a will do for me?</h4>
            <p className="willBlockText">
              Having a Will enforces your wishes by law. So, in the event of your death,
              your assets will be allocated to your loved ones.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WillBlock;
