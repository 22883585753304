import * as React from "react";

import "./Title.scss";

const Title = ({header, customStyle, quoteTitle, monthlyPremium}) => {
  return (
    <div>
      <h3
        className={`${"titleHeader"} ${quoteTitle} ${monthlyPremium}`}
        style={customStyle}
      >
        {header}
      </h3>
    </div>
  );
};

export default Title;
