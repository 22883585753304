import * as React from "react";

import ContentsSection from "./ContentsSection";

const Banner = ({firstText, secondText, thirdText, children, bgImage, customClass}) => (
  <ContentsSection
    bgImage={bgImage}
    firstText={firstText}
    secondText={secondText}
    thirdText={thirdText}
    customClass={customClass}
  >
    {children}
  </ContentsSection>
);

export default Banner;
