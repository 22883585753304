//FINCLOUD BACKEND DATA SUBMISSION
export const partnerRef = {
  GUARDRISK: 1,
  MIWAY: 2,
  ONEPLAN: 3,
  LEGALEX: 4,
  CONTACTFORM: 5,
};

//TERMS AND CONDITIONS MODAL
export const partnerRefName = [
  {
    id: 1,
    partnerName: "NiftyCover(Pty)",
  },
  {
    id: 2,
    partnerName: "Miway Insurance Limited",
  },
  {
    id: 3,
    partnerName: "OnePlan Underwriting Managers (Pty) Ltd",
  },
  {
    id: 4,
    partnerName: "Legalex (Pty) Ltd",
  },
  {
    id: 5,
    partnerName: "NIFTYCOVER_CONTACT_FORM",
  },
];
