import React, {Component} from "react";
import _ from "lodash";
import axios from "axios";

import {mappingArticlesWithImages} from "./utils";
import {BASE_URL} from "./constants/configs";

const SureContext = React.createContext();

class SuresProvider extends Component {
  state = {
    newsArticles: [],
    articleMsgs: [],
    articleList: [],
    latestArticles: [],
    c2sProducts: [],
    loading: true,
    assets: null,
    error: null,
    formSubmitted: false,
    quoteProductId: null,
    parentPackage: [],
    coverProductId: null,
  };

  componentDidMount = async () => {
    await this.getContentfulData();
  };

  sendLeadsData = async data => {
    try {
      await axios.post(BASE_URL.proxyServer + "leads", data).then(
        async response => {
          if (response.status === 200) {
            await this.setState({
              formSubmitted: true,
            });
          }
        },
        error => {}
      );
    } catch (error) {
      await this.setState({
        error: "did not send message",
      });
    }
  };

  getContentfulData = async () => {
    try {
      const contentfulData = await axios.get(BASE_URL.proxyServer + "contentful");
      let response = contentfulData;
      const {latestArticles, assets, articleList} = mappingArticlesWithImages(response);
      await this.setState({
        articleMsgs: articleList,
        latestArticles,
        loading: false,
        assets,
      });
    } catch (error) {
      this.setState({error});
      console.log(error);
    }
  };

  getClick2SureProducts = async productId => {
    await this.setState({loading: true, coverProductId: productId});
    const {coverProductId} = this.state;

    try {
      const c2sProducts = await axios.get(
        BASE_URL.proxyServer + "c2sProducts/product/" + coverProductId
      );
      let response = c2sProducts.data.packages;
      await this.setState({
        c2sProducts: response,
        loading: false,
        quoteProductId: c2sProducts.data.id,
      });
    } catch (error) {
      this.setState({error});
      console.log(error);
    }
  };

  getClick2SureParentsProducts = async setPackageId => {
    try {
      const productId = this.state.quoteProductId;
      const c2sParentsProducts = await axios.get(
        `${
          BASE_URL.proxyServer
        }${"c2sProducts/parents/"}${productId}${"/"}${setPackageId}`
      );
      let response = c2sParentsProducts.data;
      await this.setState({
        parentPackage: response,
      });
    } catch (error) {
      this.setState({error});
      console.log(error);
    }
  };

  getNews = slug => {
    let tempNews = [...this.state.latestArticles];
    let articles = tempNews.map(article => article);
    let newsS = [];
    let news = _.filter(articles, function(news) {
      newsS = articles.find(news => news.slug === slug);
    });
    news = newsS;
    return news;
  };

  getMainMember = amount => {
    try {
      const {c2sProducts} = this.state;
      const findMainMember = c2sProducts.find(prod => prod.name === "Main Member Only");
      let tempMainMember = c2sProducts;
      let mainMember = tempMainMember.map(id => id);
      let newMainMember = mainMember.find(newMainMembers => {
        let newMain = newMainMembers.id === findMainMember.id;
        return newMain;
      });
      const getPackageId = newMainMember.id;
      const getMainMemberAmt = newMainMember.cover_levels.find(
        amt => amount === amt.cover_amount
      );

      return {
        getMainMemberAmt,
        getPackageId,
      };
    } catch (error) {
      console.log(error, "get main member context error");
    }
  };

  getMainMemberAndChildren = amount => {
    const fiveChildren = "Main Member and Children (up to 5)";
    const twoChildren = "Main Member and 2 Children";

    try {
      const {c2sProducts} = this.state;
      const findMainMember = c2sProducts.find(
        prod => prod.name === fiveChildren || prod.name === twoChildren
      );
      let tempMainMemberChildren = c2sProducts;
      let mainMemberChildren = tempMainMemberChildren.map(id => id);
      let newMainMemberChildren = mainMemberChildren.find(newMainMemberChild => {
        let newMainChild = newMainMemberChild.id === findMainMember.id;
        return newMainChild;
      });
      const getPackageId = newMainMemberChildren.id;
      const getMainMemberChildAmt = newMainMemberChildren.cover_levels.find(
        amt => amount === amt.cover_amount
      );

      return {
        getMainMemberChildAmt,
        getPackageId,
      };
    } catch (error) {
      console.log(error, "get main member & children context error");
    }
  };

  getMainMemChildAndSpouse = amount => {
    const fiveChildren = "Main Member and Spouse and Children";
    const twoChildren = "Main Member, Spouse + 2 Children";

    try {
      const {c2sProducts} = this.state;
      const findMainMember = c2sProducts.find(
        prod => prod.name === fiveChildren || prod.name === twoChildren
      );
      let tempMainMemChildAndSpouse = c2sProducts;
      let mainMemChildAndSpouse = tempMainMemChildAndSpouse.map(id => id);
      let newMainMemsChildAndSpouse = mainMemChildAndSpouse.find(
        newMainMemChildAndSpouse => {
          let newMainChildAndSpouse = newMainMemChildAndSpouse.id === findMainMember.id;
          return newMainChildAndSpouse;
        }
      );
      const getPackageId = newMainMemsChildAndSpouse.id;
      const getMainMemChildAndSpouseAmt = newMainMemsChildAndSpouse.cover_levels.find(
        amt => amount === amt.cover_amount
      );

      return {
        getMainMemChildAndSpouseAmt,
        getPackageId,
      };
    } catch (error) {
      console.log(error, "get main member, child & spouse context error");
    }
  };

  getMainMemAndSpouse = amount => {
    try {
      const {c2sProducts} = this.state;
      const findMainAndSpouse = c2sProducts.find(
        prod => prod.name === "Main Member and Spouse"
      );
      let tempMainMemAndSpouse = c2sProducts;
      let mainMemAndSpouse = tempMainMemAndSpouse.map(id => id);
      let newMainMemsAndSpouse = mainMemAndSpouse.find(newMainMemAndSpouse => {
        let newMainAndSpouse = newMainMemAndSpouse.id === findMainAndSpouse.id;
        return newMainAndSpouse;
      });
      const getPackageId = newMainMemsAndSpouse.id;
      const getMainMemAndSpouseAmt = newMainMemsAndSpouse.cover_levels.find(
        amt => amount === amt.cover_amount
      );

      return {
        getMainMemAndSpouseAmt,
        getPackageId,
      };
    } catch (error) {
      console.log(error, "get main member & spouse context error");
    }
  };

  addParentToCovers = amount => {
    try {
      let tempParent = this.state.parentPackage;
      const addParentCover = tempParent.find(amt => amount === amt.cover_amount);
      return addParentCover;
    } catch (error) {
      console.log(error, "get parent context error");
    }
  };

  render() {
    return (
      <SureContext.Provider
        value={{
          ...this.state,
          getNews: this.getNews,
          getMainMember: this.getMainMember,
          getMainMemberAndChildren: this.getMainMemberAndChildren,
          getMainMemChildAndSpouse: this.getMainMemChildAndSpouse,
          getMainMemAndSpouse: this.getMainMemAndSpouse,
          addParentToCovers: this.addParentToCovers,
          sendLeadsData: this.sendLeadsData,
          getClick2SureProducts: this.getClick2SureProducts,
          getClick2SureParentsProducts: this.getClick2SureParentsProducts,
        }}
      >
        {this.props.children}
      </SureContext.Provider>
    );
  }
}

const SureConsumer = SureContext.Consumer;

export {SureContext, SuresProvider, SureConsumer};
