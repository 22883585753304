import React, {Suspense, lazy} from "react";
import ReactGA from "react-ga";
import {Container} from "react-bootstrap";
import SEO from "../components/SEO";
import MetaData from "../components/MetaData";
import Buttons from "../components/Buttons";
import GetQuote from "../components/GetQuote/GetQuote";
import Banner from "../components/Global/Banner";
import CoverPlanIntro from "../components/Home/CoverPlanIntro";
import FlexibleOptions from "../components/Home/FlexibleOptions";
import ChooseNifty from "../components/Home/ChooseNifty";
import OurPartners from "../components/Home/OurPartners";
import "./index.scss";
import config from "../constants/configs";

const HomeSlider = lazy(() => import("../components/Home/HomeSlider"));

const Home = () => {
  return (
    <div>
      <MetaData title="Home" />
      <SEO title="Home" />
      <Suspense fallback={<h1>{/*blank */}</h1>}>
        <HomeSlider />
      </Suspense>
      <Container>
        <CoverPlanIntro />
        {/*}<GetQuote /> */}
        <GetQuote
          data-scroll
          id={"getQuote"}
          backgroundStyle="funeral"
          quoteTitle="quoteTitle"
          amountNeeded="amountNeeded"
          focusAmount="focusAmount"
          monthlyPremium="monthlyPremium"
          monthlyPremiumText="monthlyPremiumText"
          mainMember="mainMember"
          addFamilyMembers="addFamilyMembers"
          rightSideFormBlock="rightSideFormBlock"
          rightBlockCheckbox="rightBlockCheckbox"
          rightFuneralCheckbox="rightFuneralCheckbox"
          showCallBackBtn="showCallBackBtn"
        />
        <Banner
          bgImage={require("../assets/images/freeWillBanner.jpg")}
          firstText="Get funeral cover today to claim your"
          secondText="free will worth R550."
          customClass="freeWillBanner"
        >
          <ReactGA.OutboundLink
            eventLabel="smart will button in smart will page"
            to={config.SMART_WILL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Buttons
              type="button"
              customStyle="freeWillBtn"
              title="Claim your free Will here"
            />
          </ReactGA.OutboundLink>
        </Banner>
        <FlexibleOptions />
        <ChooseNifty />
        <OurPartners />
      </Container>
    </div>
  );
};

export default Home;
