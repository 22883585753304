// import NiftySureIcon from "../assets/images/whiteNSIcon.svg";
import WaitingPeriod from "../assets/images/waitingPeriod.svg";
import DocumentIcon from "../assets/images/documentIcon.svg";
import NonPaymentIcon from "../assets/images/nonPaymentIcon.svg";
import CoolingIcon from "../assets/images/coolingIcon.svg";
import WrittenIcon from "../assets/images/writtenIcon.svg";

export default [
  // {
  //   image: {
  //     class: "nsIcon",
  //     alt: "nsIcon",
  //     url: NiftySureIcon,
  //   },
  //   text: "",
  //   divClass: "featGsIcon",
  // },
  {
    image: {
      class: "waitingIcon",
      alt: "waiting period",
      url: WaitingPeriod,
    },
    text: "Claims paid within 48 hours from approval",
    divClass: "policyBox",
  },
  {
    image: {
      class: "documentIcon",
      alt: "document icon",
      url: DocumentIcon,
    },
    text: "No Medicals required",
    // smallText: "(other exclusions detailed in policy document)",
    divClass: "policyBox",
  },
  {
    image: {
      class: "nonIcon",
      alt: "non payment icon",
      url: NonPaymentIcon,
    },
    text: "Flexible Claim Options",
    divClass: "policyBox",
  },
  {
    image: {
      class: "coolingIcon",
      alt: "cooling icon",
      url: CoolingIcon,
    },
    text: "Cover up to R50 000",
    divClass: "policyBox",
  },
  {
    image: {
      class: "writtenIcon",
      alt: "underwritten icon",
      url: WrittenIcon,
    },
    text: "Underwritten by Guardrisk Life Ltd",
    divClass: "policyBox",
  },
];
