import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {NavLink} from "react-router-dom";
import {Nav, NavDropdown} from "react-bootstrap";

import "./Menu.scss";

const Menu = () => {
  return (
    <>
      <Nav className="ml-auto" activeKey="/">
        <NavLink
          exact
          activeClassName="navbar__link--active"
          className="navbar__link"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          activeClassName="navbar__link--active"
          className="navbar__link"
          to="/about-us"
        >
          About
        </NavLink>
        <NavDropdown
          title="Products"
          id="collasible-nav-dropdown"
          className="navbar__link"
        >
          <NavDropdown.Item href="/products/funeral" className="navDropdown">
            Funeral
          </NavDropdown.Item>
          <NavDropdown.Divider className="verticalLine" />
          <NavDropdown.Item href="/products/commuter" className="navDropdown">
            Commuter
          </NavDropdown.Item>
          <NavDropdown.Divider className="verticalLine" />
          <NavDropdown.Item href="/products/credit-life" className="navDropdown">
            Credit Life
          </NavDropdown.Item>
          {/* <NavDropdown.Divider className="verticalLine" />
          <NavDropdown.Item href="/products/smart-will" className="navDropdown">
            SmartWill
          </NavDropdown.Item>
          <NavDropdown.Divider className="verticalLine" />
          <NavDropdown.Item href="/products/short-term-insurance" className="navDropdown">
            Short-Term Insurance
          </NavDropdown.Item> */}
          <NavDropdown.Divider className="verticalLine" />
        </NavDropdown>
        <NavLink
          activeClassName="navbar__link--active"
          className="navbar__link"
          to="/news"
        >
          News
        </NavLink>
        <AnchorLink href="#contact-us" className="contactStyle navbar__link">
          Contact
        </AnchorLink>
        <NavLink
          activeClassName="navbar__link--active"
          className="navbar__link"
          to="/faq"
        >
          FAQ
        </NavLink>
      </Nav>
    </>
  );
};

export default Menu;
