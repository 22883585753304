import React from "react";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";
import ShortTermInsurance from "../components/Products/ShortTermInsurance";

const ShortTermInsurances = () => {
  return (
    <div>
      <MetaData title="Short term insurance" />
      <SEO title="Short term insurance" />
      <ShortTermInsurance />
    </div>
  );
};

export default ShortTermInsurances;
