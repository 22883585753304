import React, {useState, Suspense, lazy} from "react";

import Buttons from "../Buttons";
import {Event} from "../Tracking";

import "./CallMeBackBtn.scss";

import {partnerRef} from "../../constants/partnerRefEnums";

const GetQuoteModal = lazy(() => import("../QuoteModal"));

const CallMeBackBtn = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    Event("CALLMEBACK", "Funeral Quote", "FUNERAL_PAGE");
  };

  return (
    <>
      <Suspense fallback={<h1>Loading Call Back Modal</h1>}>
        <Buttons
          type="button"
          title="Call me back"
          customStyle="callBack"
          onClick={handleShow}
        />
        <GetQuoteModal
          show={show}
          partnerRefId={partnerRef.GUARDRISK}
          handleClose={handleClose}
        />
      </Suspense>
    </>
  );
};

export default CallMeBackBtn;
