import * as React from "react";
import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import CoverPlanBlock from "./CoverPlanBlock";
import Buttons from "../Buttons";

import "./CoverPlanIntro.scss";

const CoverPlanIntro = () => {
  return (
    <Row>
      <Col>
        <Title header="Fin Cover" customStyle={{}} />
        <div className="funeralText">
          <h5>Change the way you feel about insurance.</h5>
          <p>
            We understand that life is unpredictable and the best thing we can do for you
            and the well-being of your family is to prepare you; ensuring that your
            estate, your family and your assets are taken care of. Fin Cover Funeral &
            Commuter products provide you with the insurance cover you need to be able to
            protect yourself and your loved ones for the future financially.
          </p>
          <Link to="/products/funeral">
            <Buttons type="button" customStyle="sliderLearnMore" title="Learn More" />
          </Link>
          <CoverPlanBlock />
        </div>
      </Col>
    </Row>
  );
};

export default CoverPlanIntro;
