export default {
  SMART_WILL:
    process.env.REACT_APP_SMART_WILL ||
    "https://smartwill.co.za/nifty?promocode=niftycover&utm_medium=broker&utm_source=email&utm_campaign=nifty&utm_content=promonifty&utm_term=nifty",
  GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || "UA-120613711-1",
  NIFTYCOVER_CONTACT_EMAIL:
    process.env.REACT_APP_NIFTYCOVER_CONTACT_EMAIL || "coverinfoza@fin.africa",
  NIFTYCOVER_CONTACT_NUMBER:
    process.env.REACT_APP_NIFTYCOVER_CONTACT_NUMBER || "+27 12 045 0604",
  LINKEDIN_URL:
    process.env.REACT_APP_LINKEDIN_URL || "https://www.linkedin.com/company/hqfinafrica/",
  TWITTER_URL: process.env.REACT_APP_TWITTER_URL || "https://twitter.com/finafricaza",
  INSTAGRAM_URL:
    process.env.REACT_APP_INSTAGRAM_URL || "https://www.instagram.com/finafricaza/",
  FACEBOOK_URL:
    process.env.REACT_APP_FACEBOOK_URL || "https://www.facebook.com/finafricaza",
  REDIRECT_URL:
    process.env.REACT_APP_REDIRECT_URL ||
    "https://niftycover-sales.click2sure.co.za/sales-flow/customer-details?",
  NIFTYCOVER_LOGIN:
    process.env.REACT_APP_NIFTYCOVER_LOGIN ||
    "https://niftycover.click2sure.co.za/login/",
  TRU_ID:
    process.env.REACT_APP_TRU_ID ||
    "https://mytransunion.co.za/Products/MyCredit/YCR905?NewBasketCode=YCR905&Referrer=smartadvance&utm_source=smartadvance&utm_medium=Website&utm_campaign=TrueIdentity",
};

export const BASE_URL = {
  proxyServer: process.env.REACT_APP_PROXY_SERVER,
};
