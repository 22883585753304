import * as React from "react";
import ReactGA from "react-ga";
import {Navbar, Form} from "react-bootstrap";

import Menu from "./Menu";
import Buttons from "../Buttons";

import "./NavBar.scss";

import Logo from "../../assets/images/logo-new.svg";

import config from "../../constants/configs";

const NavBar = () => {
  return (
    <>
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img src={Logo} alt="logo" className="getsureLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Menu />
          {/* <Form inline>
            <a
              href={config.NIFTYCOVER_LOGIN}
              onClick={event => {
                const script = document.createElement("script");
                document.head.append(script);
                script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-N5JPGHJ');
                `;
              }}
            >
              <Buttons type="button" customStyle="loginButton" title="login" />
            </a>{" "}
            &nbsp;&nbsp;
            <ReactGA.OutboundLink
              eventLabel="claim button in footer"
              to={config.NIFTYCOVER_LOGIN}
              rel="noopener noreferrer"
            >
              <Buttons type="button" customStyle="claimButton" title="claim" />
            </ReactGA.OutboundLink>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
