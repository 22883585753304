import * as React from "react";
import {Row, Col, Form} from "react-bootstrap";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import Buttons from "../Buttons";
import CallMeBackBtn from "./CallMeBackBtn";
import ErrorBoundary from "../ErrorBoundary";
import SelectAmount from "./SelectAmount";
import LetsDoItBtn from "./LetsDoItBtn";
import Title from "../Title";

import "./GetQuoteForm.scss";

import {SureContext} from "../../context";
import {
  isMainMember,
  filterChildren,
  filterSpouse,
  filterParents,
  mapChildren,
  mapSpouses,
  mapParents,
  getStatusForChildAndSpouse,
} from "../../utils";

class GetQuoteForm extends React.Component {
  static contextType = SureContext;
  constructor(props) {
    super(props);

    this.state = {
      mainMemberName: "",
      mainMemberAge: "",
      isSpouseChecked: true,
      isParentChecked: true,
      isChildrenChecked: true,
      parents: [{name: ""}],
      children: [{name: ""}],
      spouses: [{name: ""}],
      selectedPlan: 0,
      currentAmount: 0,
      coverLevelId: null,
      setPackageId: 2,
      productId: null,
    };
  }

  handleParentChange = async e => {
    e.preventDefault();
    const value = e.target.value;
    const id = e.target.dataset.id;
    const parents = [...this.state.parents];
    let parent = {...parents[id]};
    parent.name = value;
    parents[id] = parent;
    const getParents = mapParents(parents);
    await this.setState({
      parents: getParents.length <= 0 ? [] : parents,
    });
    this.checkPremiumsOrder();
  };

  handleChildChange = async e => {
    e.preventDefault();
    const value = e.target.value;
    const id = e.target.dataset.id;
    const children = [...this.state.children];
    let child = {...children[id]};
    child.name = value;
    children[id] = child;
    const getChildren = mapChildren(children);
    await this.setState({
      children: getChildren.length <= 0 ? [] : children,
    });
    this.checkPremiumsOrder();
  };

  handleSpouseChange = async e => {
    e.preventDefault();
    const value = e.target.value;
    const id = e.target.dataset.id;
    const spouses = [...this.state.spouses];
    let spouse = {...spouses[id]};
    spouse.name = value;
    spouses[id] = spouse;
    const getSpouses = mapSpouses(spouses);
    await this.setState({
      spouses: getSpouses.length <= 0 ? [] : spouses,
    });

    this.checkPremiumsOrder();
  };

  addParent = event => {
    event.preventDefault();
    const {parents} = this.state;
    parents.push({name: ""});
    this.setState({parents});
  };

  addChild = event => {
    event.preventDefault();
    const {children} = this.state;
    children.push({name: ""});
    this.setState({children});
  };

  addSpouse = event => {
    event.preventDefault();
    const {spouses} = this.state;
    spouses.push({name: ""});
    this.setState({spouses});
  };

  handleSpouseCheck = async e => {
    const isSpouseChecked = !this.state.isSpouseChecked;

    await this.setState({
      isSpouseChecked,
      spouses: !isSpouseChecked ? [{name: ""}] : this.state.spouses,
    });

    this.checkPremiumsOrder();
  };

  handleParentCheck = async e => {
    const isParentChecked = !this.state.isParentChecked;

    await this.setState({
      isParentChecked,
      parents: !isParentChecked ? [{name: ""}] : this.state.parents,
    });

    this.checkPremiumsOrder();
  };

  handleChildrenCheck = async e => {
    const isChildrenChecked = !this.state.isChildrenChecked;

    await this.setState({
      isChildrenChecked,
      children: !isChildrenChecked ? [{name: ""}] : this.state.children,
    });

    this.checkPremiumsOrder();
  };

  handleChange = async event => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });

    this.checkPremiumsOrder();
  };

  getCurrentAmount = async amount => {
    await this.setState(state => ({
      currentAmount: amount,
    }));
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.currentAmount !== this.state.currentAmount) {
      this.checkPremiumsOrder();
    }

    if (prevState.productId !== this.state.productId) {
      this.resetState();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      productId: nextProps.productId,
    };
  }

  resetState = () => {
    this.setState({
      mainMemberName: "",
      mainMemberAge: "",
      isSpouseChecked: true,
      isParentChecked: true,
      isChildrenChecked: true,
      parents: [{name: ""}],
      children: [{name: ""}],
      spouses: [{name: ""}],
      selectedPlan: 0,
      currentAmount: 0,
      coverLevelId: null,
      setPackageId: 2,
    });
  };

  getCurrentMainMember = async () => {
    const {currentAmount} = this.state;
    let {getMainMember} = this.context;
    const {getMainMemberAmt, getPackageId} = await getMainMember(currentAmount);

    return {
      selectedPlan: getMainMemberAmt.premium,
      coverLevelId: getMainMemberAmt.id,
      setPackageId: getPackageId,
    };
  };

  getCurrentMainMemberAndChildren = async () => {
    const {currentAmount} = this.state;
    let {getMainMemberAndChildren} = this.context;
    const {getMainMemberChildAmt, getPackageId} = await getMainMemberAndChildren(
      currentAmount
    );

    return {
      selectedPlan: getMainMemberChildAmt.premium,
      coverLevelId: getMainMemberChildAmt.id,
      setPackageId: getPackageId,
    };
  };

  getCurrentMainMemChildAndSpouse = async () => {
    const {currentAmount} = this.state;
    let {getMainMemChildAndSpouse} = this.context;
    const {getMainMemChildAndSpouseAmt, getPackageId} = await getMainMemChildAndSpouse(
      currentAmount
    );

    return {
      selectedPlan: getMainMemChildAndSpouseAmt.premium,
      coverLevelId: getMainMemChildAndSpouseAmt.id,
      setPackageId: getPackageId,
    };
  };

  getCurrentMainMemAndSpouse = async () => {
    const {currentAmount} = this.state;
    let {getMainMemAndSpouse} = this.context;
    const {getMainMemAndSpouseAmt, getPackageId} = await getMainMemAndSpouse(
      currentAmount
    );

    return {
      selectedPlan: getMainMemAndSpouseAmt.premium,
      coverLevelId: getMainMemAndSpouseAmt.id,
      setPackageId: getPackageId,
    };
  };

  addParentToSelectedPremium = async (selectedPlan, coverLevelId, setPackageId) => {
    const {currentAmount, parents} = this.state;
    let {addParentToCovers, getClick2SureParentsProducts} = this.context;
    await getClick2SureParentsProducts(setPackageId);
    const addParents = await addParentToCovers(currentAmount);
    var numOfValInForm = filterParents(parents).length;
    if (selectedPlan > 0 && typeof selectedPlan !== "undefined") {
      const addValue = selectedPlan + addParents.premium * numOfValInForm;
      return {
        selectedPlan: addValue,
        coverLevelId,
        setPackageId,
      };
    }
  };

  debouncedFunc = () => AwesomeDebouncePromise(this.checkPremiumsOrder(), 100);

  checkPremiumsOrder = async () => {
    const {mainMemberName, mainMemberAge, children, parents, spouses} = this.state;

    let value = {};

    if (
      isMainMember(mainMemberName, mainMemberAge) &&
      filterChildren(children).length <= 0 &&
      filterSpouse(spouses).length <= 0
    ) {
      value = await this.getCurrentMainMember();
    } else if (
      isMainMember(mainMemberName, mainMemberAge) &&
      filterChildren(children).length > 0 &&
      filterSpouse(spouses).length <= 0
    ) {
      value = await this.getCurrentMainMemberAndChildren();
    } else if (
      isMainMember(mainMemberName, mainMemberAge) &&
      getStatusForChildAndSpouse(children, spouses)
    ) {
      value = await this.getCurrentMainMemChildAndSpouse();
    } else if (
      isMainMember(mainMemberName, mainMemberAge) &&
      filterSpouse(spouses).length > 0 &&
      filterChildren(children).length <= 0
    ) {
      value = await this.getCurrentMainMemAndSpouse();
    }

    //HACK: to prevent parent value from reappearing after field is empty, side effect, parent api calls always
    // if (filterParents(parents)) {
    if (
      isMainMember(mainMemberName, mainMemberAge) &&
      filterParents(parents).length > 0
    ) {
      value = await this.addParentToSelectedPremium(
        value.selectedPlan,
        value.coverLevelId,
        value.setPackageId
      );
    }

    await this.setState({
      selectedPlan: value.selectedPlan,
      coverLevelId: value.coverLevelId,
      setPackageId: value.setPackageId,
    });
  };

  render() {
    const {
      parents,
      children,
      spouses,
      isSpouseChecked,
      isParentChecked,
      isChildrenChecked,
      coverLevelId,
      mainMemberName,
      mainMemberAge,
      setPackageId,
      selectedPlan = 0, //HACK: to prevent undefined
    } = this.state;

    const {
      amountNeeded,
      quoteTitle,
      focusAmount,
      monthlyPremium,
      monthlyPremiumText,
      mainMember,
      rightSideFormBlock,
      addFamilyMembers,
      rightBlockCheckbox,
      showCallBackBtn,
      rightFuneralCheckbox,
      productId,
    } = this.props;

    if (this.context.loading) {
      return null;
    }

    const letsGoStyle = !showCallBackBtn ? `${"letsGoBlock"}` : `${"funeralLetsGoBlock"}`;

    return (
      <div>
        <Row>
          <Col xs={{span: 12, order: 2}} md={{span: 4, order: 1}}>
            <div className="titleShow">
              <Title
                header="Get a quote now"
                quoteTitle={quoteTitle}
                customStyle={{
                  color: `${"var(--mainIrisBlue)"}`,
                  textAlign: `left`,
                  padding: `0em`,
                }}
              />
            </div>
            <h6 className={`${"coverHeader"} ${amountNeeded}`}>
              How much cover do you need?
            </h6>
            <Form>
              <div>
                <ErrorBoundary>
                  <SelectAmount
                    focusAmount={focusAmount}
                    productId={productId}
                    currentAmount={amount => this.getCurrentAmount(amount)}
                  />
                </ErrorBoundary>
              </div>
            </Form>
            <div className={letsGoStyle}>
              <Title
                header={`${"R"} ${selectedPlan}`}
                customStyle={{
                  color: `var(--mainWhite)`,
                  textAlign: `center`,
                  padding: `.2em 0em 0em`,
                  fontSize: `3em`,
                }}
                monthlyPremium={monthlyPremium}
              />
              <p className={`${"quotePremium"} ${monthlyPremiumText}`}>Monthly Premium</p>
              <div className={showCallBackBtn ? `${"doubleBtns"}` : ""}>
                <LetsDoItBtn
                  coverLevel={coverLevelId}
                  mainMemberName={mainMemberName}
                  mainMemberAge={mainMemberAge}
                  setPackageId={setPackageId}
                />
                {showCallBackBtn ? <CallMeBackBtn /> : ""}
              </div>
            </div>
          </Col>
          <Col
            xs={{span: 12, order: 1}}
            md={{span: 8, order: 2}}
            className={`${"quoteFormStyle"} ${rightSideFormBlock}`}
          >
            <div className="titleHide">
              <Title
                header="Get a quote now"
                quoteTitle={quoteTitle}
                customStyle={{
                  color: `${"var(--mainGetsureGreen)"}`,
                  textAlign: `left`,
                  padding: `0em`,
                }}
              />
            </div>
            <h6 className={`${"coverHeaderTwo"} ${mainMember}`}>Main member age?</h6>
            <Form>
              <Form.Row>
                {/*}<Form.Group as={Col} md={7}>
                  <Form.Control
                    required
                    type="text"
                    name="mainMemberName"
                    value={mainMemberName}
                    onChange={this.handleChange}
                    autoComplete="off"
                    placeholder="Full Name"
                  />
                </Form.Group> */}
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    required
                    name="mainMemberAge"
                    value={mainMemberAge}
                    onChange={this.handleChange}
                    autoComplete="off"
                    type="number"
                    placeholder="Age"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
            <h6 className={`${"coverHeaderThree"} ${addFamilyMembers}`}>
              Would you like to add some family members?
            </h6>
            <Form>
              <Form.Row>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <div className={`${"checkbox"} ${rightFuneralCheckbox}`}>
                      <input
                        type="checkbox"
                        id="checkbox-1-1"
                        onChange={this.handleSpouseCheck}
                        checked={isSpouseChecked}
                      />
                      <label htmlFor="checkbox-1-1" className={`${rightBlockCheckbox}`}>
                        <span>Spouse</span>
                      </label>
                    </div>
                  </Form.Group>
                  {spouses.map(({name}, id) => {
                    const spouseId = `spouse-${id}`;
                    return (
                      <div key={spouseId}>
                        <Form.Group as={Col}>
                          <Form.Control
                            required
                            type="number"
                            name={spouseId}
                            data-id={id}
                            id={spouseId}
                            onChange={this.handleSpouseChange}
                            value={spouses[id].name || ""}
                            className={
                              !isSpouseChecked ? "form-control:disabled" : "form-control"
                            }
                            placeholder="Age"
                            autoComplete="off"
                            disabled={!isSpouseChecked ? "disabled" : ""}
                          />
                        </Form.Group>
                      </div>
                    );
                  })}
                  <div className="buttonCenter">
                    {spouses.length <= 1 ? (
                      <Buttons
                        type="button"
                        customStyle="addAnother"
                        title="Add another"
                        onClick={this.addSpouse}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <div className={`${"checkbox"} ${rightFuneralCheckbox}`}>
                      <input
                        type="checkbox"
                        id="checkbox-1-2"
                        onChange={this.handleChildrenCheck}
                        checked={isChildrenChecked}
                      />
                      <label htmlFor="checkbox-1-2" className={`${rightBlockCheckbox}`}>
                        <span>Children</span>
                      </label>
                    </div>
                  </Form.Group>
                  {children.map(({name}, id) => {
                    const childId = `child-${id}`;
                    return (
                      <div key={childId}>
                        <Form.Group as={Col}>
                          <Form.Control
                            required
                            type="number"
                            name={childId}
                            data-id={id}
                            id={childId}
                            onChange={this.handleChildChange}
                            value={children[id].name || ""}
                            className={
                              !isChildrenChecked
                                ? "form-control:disabled"
                                : "form-control"
                            }
                            placeholder="Age"
                            autoComplete="off"
                            disabled={!isChildrenChecked ? "disabled" : ""}
                          />
                        </Form.Group>
                      </div>
                    );
                  })}
                  <div className="buttonCenter">
                    {children.length <= 4 ? (
                      <Buttons
                        type="button"
                        customStyle="addAnother"
                        title="Add another"
                        onClick={this.addChild}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <div className={`${"checkbox"} ${rightFuneralCheckbox}`}>
                      {productId === 3 && (
                        <input
                          type="checkbox"
                          id="checkbox-1-3"
                          onChange={this.handleParentCheck}
                          checked={isParentChecked}
                        />
                      )}
                      <label htmlFor="checkbox-1-3" className={`${rightBlockCheckbox}`}>
                        <div className="parentOnlyAvailable">
                          <span>Parent &nbsp;</span>
                          {productId === 4 && (
                            <span className="onlyAvailable">
                              (only available in funeral)
                            </span>
                          )}
                        </div>
                      </label>
                    </div>
                  </Form.Group>
                  {parents.map(({name}, id) => {
                    const parentId = `parent-${id}`;
                    return (
                      <div key={id}>
                        {/*<label htmlFor={parentId}>{`Parent #${id + 1}`}</label>*/}
                        <Form.Group as={Col}>
                          <Form.Control
                            required
                            name={parentId}
                            type="number"
                            data-id={id}
                            id={parentId}
                            onChange={this.handleParentChange}
                            value={parents[id].name || ""}
                            className={
                              !isParentChecked ? "form-control:disabled" : "form-control"
                            }
                            placeholder="Age"
                            autoComplete="off"
                            disabled={
                              !isParentChecked || productId === 4 ? "disabled" : ""
                            }
                          />
                        </Form.Group>
                      </div>
                    );
                  })}
                  {productId === 3 && (
                    <div className="buttonCenter">
                      {parents.length <= 3 ? (
                        <Buttons
                          type="button"
                          customStyle="addAnother"
                          title="Add another"
                          onClick={this.addParent}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </Col>
              </Form.Row>
            </Form>
            <p className="premText">
              <span>
                Premiums are guaranteed for the first 12 months and are renewable
                annually. Any increase will be notified to the Main Member 31 days before
                such an annual increase takes place.
              </span>
              <br />
              <span>
                The policy is underwritten by Guardrisk Life Limited, a licensed life
                insurer and authorised financial services provider (FSP no. 76).
              </span>
              <br />
              <span>A product of NiftyCover.</span>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default GetQuoteForm;
