import NeededCover from "../assets/images/neededCover.svg";
import FuneralPlanCover from "../assets/images/funeralPlanCover.svg";
import AskPlanCover from "../assets/images/askPlanCover.svg";
import HowItWorks from "../assets/images/howItWorks.svg";

export const coverQuestions = [
  {
    id: "coverQuestions1",
    image: {
      url: AskPlanCover,
      alt: "funeral plan cover",
      class: "askPlanCover",
    },
    className: "pearlBox",
    text: "Why do I need funeral cover?",
    longTextOne:
      "On average a funeral can cost up to R30 000 and sometimes more. Having a funeral policy gives you peace of mind that you, your family and loved ones are covered in the unfortunate event of their death.",
  },
  {
    id: "coverQuestions2",
    image: {
      url: FuneralPlanCover,
      alt: "funeral plan cover",
      class: "planCoverQ",
    },
    className: "purpleBox",
    text: "What does a funeral plan cover?",
    longTextOne:
      "Fin Cover will payout a cash lump-sum based on the funeral plan that you choose to pay for the necessary costs involved with a funeral. You will be able to use these funds as you wish.",
  },
  {
    id: "coverQuestions3",
    image: {
      url: HowItWorks,
      alt: "funeral plan cover",
      class: "howItWorks",
    },
    className: "greenBox",
    text: "How does Funeral cover work?",
    longTextOne:
      "Getting a funeral plan from Fin Cover is very easy and can be done in just a couple of minutes. Once you choose the plan to suit your family and the cover amount that you can afford, you will pay a monthly premium towards the funeral policy. In the event of death, a claim is submitted to Fin Cover by your beneficiary and a lump sum will be paid out to take care of the costs of the funeral.",
  },
  {
    id: "coverQuestions4",
    image: {
      url: NeededCover,
      alt: "funeral plan cover",
      class: "neededCover",
    },
    className: "yellowBox",
    text: "How do I know what funeral cover I need?",
    longTextOne:
      "Needs differ from person to person and family to family. When looking at the options available and which might be suitable for you, consider how many people will be covered in the policy. Is it just for you, you and your spouse or for your whole family?",
    longTextTwo:
      "Then consider what amount you can afford every month and what you will need to cover in your plan. Will it be just the funeral? This will determine the cover amount you choose.",
  },
];

export const displaySummaries = [
  {
    id: "coverQuestions1",
    longTextOne:
      "On average a funeral can cost up to R30 000 and sometimes more. Having a funeral policy gives you peace of mind that you, your family and loved ones are covered in the unfortunate event of their death.",
  },
  {
    id: "coverQuestions2",
    longTextOne:
      "We will payout a cash lump-sum based on the funeral plan you choose to pay for the necessary costs involved with a funeral. You will be able to use these funds as you wish.",
  },
  {
    id: "coverQuestions3",
    longTextOne:
      "Getting a funeral plan is easy and can be done in just a few minutes. Once you choose the plan to suit your family and the cover amount you can afford, you will pay a monthly premium for the funeral policy. In the event of death, a claim is submitted by your beneficiary, and a lump sum will be paid out to take care of the costs of the funeral.",
  },
  {
    id: "coverQuestions4",
    longTextOne:
      "Needs differ from person to person and family to family. When looking at the options available and which might be suitable for you, consider how many people will be covered in the policy. Is it just for you, you and your spouse or for your whole family?",
    longTextTwo:
      "Then consider what amount you can afford every month and what you will need to cover in your plan. Will it be just the funeral? This will determine the cover amount you choose.",
  },
];
