import React, {useState, useEffect, useContext} from "react";
import ChooseCover from "./ChooseCover";
import GetQuoteForm from "./GetQuoteForm";
import {SureContext} from "../../context";
import "./GetQuote.scss";

const GetQuote = props => {
  const products = {
    funeral: 3,
    commuter: 4,
  };

  const context = useContext(SureContext);
  const [productId, setProductId] = useState(
    window.location.pathname !== "products/commuter"
      ? products.funeral
      : products.commuter
  );

  const updateProductId = cover => {
    const setProdId = products[cover];

    setProductId(setProdId);
  };

  const {
    backgroundStyle,
    quoteTitle,
    amountNeeded,
    focusAmount,
    monthlyPremium,
    monthlyPremiumText,
    mainMember,
    rightSideFormBlock,
    addFamilyMembers,
    rightBlockCheckbox,
    rightFuneralCheckbox,
    showCallBackBtn,
  } = props;

  const sendProductId = () => {
    const {getClick2SureProducts} = context;
    getClick2SureProducts(productId);
  };

  useEffect(() => {
    const unsubscribe = sendProductId();

    return () => unsubscribe;
    // eslint-disable-next-line
  }, [productId]);

  return (
    <div className="quoteCategory">
      <ChooseCover sendProductId={cover => updateProductId(cover)} />
      <div className={`${"quoteSection"} ${backgroundStyle}`}>
        <GetQuoteForm
          productId={productId}
          quoteTitle={quoteTitle}
          amountNeeded={amountNeeded}
          monthlyPremium={monthlyPremium}
          monthlyPremiumText={monthlyPremiumText}
          focusAmount={focusAmount}
          addFamilyMembers={addFamilyMembers}
          rightSideFormBlock={rightSideFormBlock}
          rightBlockCheckbox={rightBlockCheckbox}
          rightFuneralCheckbox={rightFuneralCheckbox}
          showCallBackBtn={showCallBackBtn}
          mainMember={mainMember}
        />
      </div>
    </div>
  );
};

export default GetQuote;
