import React from "react";
import {useLocation} from "react-router-dom";

import Buttons from "../Buttons";

import config from "../../constants/configs";
import {isMainMember} from "../../utils";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LetsDoItBtn = ({setPackageId, coverLevel, mainMemberName, mainMemberAge}) => {
  // eslint-disable-next-line
  let query = useQuery();

  const checkValues = () => {
    const packageId = setPackageId;
    const clientIdUrl =
      config.REDIRECT_URL +
      `${`${"package_id="}${packageId}${"&cover_level_id="}${coverLevel}`}`;

    if (
      !isMainMember(mainMemberName, mainMemberAge) ||
      coverLevel === null ||
      coverLevel === undefined
    ) {
      return null;
    } else {
      return clientIdUrl;
    }
  };

  return (
    <div className="alignButton">
      <a
        href={checkValues()}
        title="lets do it"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Buttons type="submit" customStyle="quoteButton" title="Let's do it" />
      </a>
    </div>
  );
};

export default LetsDoItBtn;
