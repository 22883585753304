import * as React from "react";
import {Row, Col} from "react-bootstrap";
import GetQuote from "../../../GetQuote/GetQuote";
import Title from "../../../Title";
import "./index.scss";
import PayoutIcon from "../../../../assets/images/payoutIcon.svg";

const Index = () => {
  return (
    <div className="commuterSection">
      <Title header="Commuter Cover" />
      <h6 className="commuterHeader">Get covered from only R7.50 per month.</h6>
      <p className="commuterText">
        We understand the risks our customers face while travelling to and from work, in
        order to provide for their loved ones. Our Commuter cover is designed to take care
        of you and your family in the event of accidental death or permanent disability.
      </p>
      <div className="coverSection" data-scroll id={"flexiblePayout"}>
        <Row>
          <Col md={3}>
            <div className="coverRectangle">
              <img src={PayoutIcon} alt="cover icon" className="coverImg" />
            </div>
          </Col>
          <Col md={9} className="flexBenefits">
            <Title
              header="Cover"
              customStyle={{
                textAlign: `left`,
                paddingBottom: `1em`,
                paddingTop: `1.4em`,
                color: `var(--mainMint)`,
              }}
            />
            <ul className="benefits">
              <li>
                Covers Death and Total Permanent Disability caused by an accident while
                travelling in your own or any other vehicle including motor vehicles,
                buses, taxis, motorbikes, trains, bicycles or as a pedestrian
              </li>
              <li>Covers Main member, Spouse and 2 Children </li>
              <li>Cover amounts up to R30 000 </li>
              <li>There are no waiting periods</li>
              <li>Minimum entry age of 18 for adults</li>
              <li>Flexible claims options selected at claim stage</li>
            </ul>
          </Col>
        </Row>
      </div>
      <div>
        <GetQuote
          data-scroll
          id={"getQuote"}
          backgroundStyle="funeral"
          quoteTitle="quoteTitle"
          amountNeeded="amountNeeded"
          focusAmount="focusAmount"
          monthlyPremium="monthlyPremium"
          monthlyPremiumText="monthlyPremiumText"
          mainMember="mainMember"
          addFamilyMembers="addFamilyMembers"
          rightSideFormBlock="rightSideFormBlock"
          rightBlockCheckbox="rightBlockCheckbox"
          rightFuneralCheckbox="rightFuneralCheckbox"
          showCallBackBtn="showCallBackBtn"
        />
      </div>
    </div>
  );
};

export default Index;
