import * as React from "react";
import ReactGA from "react-ga";
import {Link} from "react-router-dom";
import {Container, Row, Col, Form} from "react-bootstrap";
import Buttons from "../../Buttons";
import "./Footer.scss";
import FooterLogo from "../../../assets/images/logo-new.svg";
import Facebook from "../../../assets/images/facebook.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Instagram from "../../../assets/images/instagram.svg";
import ConflictofInterestPolicy from "../../../assets/docs/Conflict of interest Policy 2023.pdf";
import NewNotice from "../../../assets/docs/Notice of Changes on your Funeral Insurance Policy.pdf";
import ComplaintsManagement from "../../../assets/docs/Complaints Management Framework 2023.pdf";
import ClaimsManagement from "../../../assets/docs/Niftycover_Claims_Management_Framework.pdf";
import PAIAPolicy from "../../../assets/docs/FAHSA-Group-PAIA-Policy-signed.pdf";

import config from "../../../constants/configs";

const Footer = () => (
  <div className="footerSection">
    <Container>
      <Row>
        <Col xs={12} md={2}>
          <img src={FooterLogo} alt="logo" className="footerLogo" />
          <div>
            <p className="footerAddress">
              Office G003
              <br />
              Podium at Menlyn
              <br />
              43 Ingersol Road
              <br />
              Lynnwood Glen
              <br />
              0145
            </p>
            <span className="footerNumEmail">
              <a
                href={`${"tel:"}${config.NIFTYCOVER_CONTACT_NUMBER}`}
                title={config.NIFTYCOVER_CONTACT_NUMBER}
                className="footerNum"
              >
                +27 12 045 0604
              </a>
              <br />
              <a
                href={`${"mailto:"}${config.NIFTYCOVER_CONTACT_EMAIL}`}
                title={config.NIFTYCOVER_CONTACT_EMAIL}
                className="footerEmail"
              >
                {config.NIFTYCOVER_CONTACT_EMAIL}
              </a>
            </span>
          </div>
        </Col>
        <Col xs={12} md={2}>
          <div className="footerLinks">
            <h6>Company</h6>
            <a href="/about-us">
              <p>About</p>
            </a>
            <a href="/products/funeral">
              <p>Funeral Cover</p>
            </a>
            <a href="/faq">
              <p>FAQ</p>
            </a>
            <a href="/news">
              <p>News</p>
            </a>
          </div>
        </Col>
        {/* <Col xs={12} md={2}>
          <div className="footerLinks">
            <h6>Login to manage your policy</h6>
            <ReactGA.OutboundLink
              eventLabel="view policy in footer"
              to={config.NIFTYCOVER_LOGIN}
              rel="noopener noreferrer"
            >
              <p>View and Manage Policy</p>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="update banking details in footer"
              to={config.NIFTYCOVER_LOGIN}
              rel="noopener noreferrer"
            >
              <p>Update Banking Details</p>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="submit claim in footer"
              to={config.NIFTYCOVER_LOGIN}
              rel="noopener noreferrer"
            >
              <p>Submit a Claim</p>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="change contact details in footer"
              to={config.NIFTYCOVER_LOGIN}
              rel="noopener noreferrer"
            >
              <p>Change Contact Details</p>
            </ReactGA.OutboundLink>
          </div>
        </Col> */}
        <Col xs={12} md={2}>
          <div className="footerLinks">
            <h6>Governance</h6>
            <a href={ClaimsManagement} target="_blank" rel="noopener noreferrer">
              <p>Claims Management</p>
            </a>
            <a href={ComplaintsManagement} target="_blank" rel="noopener noreferrer">
              <p>Complaints Management</p>
            </a>
            <a href={ConflictofInterestPolicy} target="_blank" rel="noopener noreferrer">
              <p>FAIS Conflict of Interest</p>
            </a>
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
            <a href={PAIAPolicy} target="_blank" rel="noopener noreferrer">
              <p>PAIA policy</p>
            </a>
            <a href={NewNotice} target="_blank" rel="noopener noreferrer">
              <p>Guardrisk change notice</p>
            </a>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="footerRightSide">
            <div className="socMediaLinks">
              <a href={config.LINKEDIN_URL} title="linkedIn">
                <img src={LinkedIn} alt="linkedin logo" className="footerLI" />
              </a>
              &nbsp;&nbsp;
              <a href={config.FACEBOOK_URL}>
                <img src={Facebook} alt="facebook logo" className="footerFB" />
              </a>
              &nbsp;&nbsp;
              <a href={config.TWITTER_URL} title="twitter">
                <img src={Twitter} alt="twitter logo" className="footerTW" />
              </a>
              &nbsp;&nbsp;
              <a href={config.INSTAGRAM_URL}>
                <img src={Instagram} alt="instagram logo" className="footerIN" />
              </a>
            </div>
            <p className="footerText">
              NiftyCover is an Authorised Financial Service Provider in terms of FAIS (FSP
              43574). Copyright {new Date().getFullYear()}. Underwritten by Guardrisk Life
              Limited, a licensed life insurer and authorised financial services provider
              (FSP no. 76). All Rights Reserved.
            </p>
            {/* <Form inline>
              <ReactGA.OutboundLink
                eventLabel="login button footer"
                to={config.NIFTYCOVER_LOGIN}
                rel="noopener noreferrer"
              >
                <Buttons type="button" customStyle="footerLogin" title="login" />
              </ReactGA.OutboundLink>{" "}
              &nbsp;&nbsp;
              <ReactGA.OutboundLink
                eventLabel="claim button in footer"
                to={config.NIFTYCOVER_LOGIN}
                rel="noopener noreferrer"
              >
                <Buttons type="button" customStyle="footerClaim" title="claim" />
              </ReactGA.OutboundLink>
            </Form> */}
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
