import * as React from "react";
import {Container} from "react-bootstrap";

import Title from "../../Title";
import Banner from "../../Global/Banner";
import ChooseInsurance from "./ChooseInsurance";

import "./index.scss";

import {insuranceTypes, legalCoverBenefits} from "../../../constants/shortTermInsurance";

class Index extends React.Component {
  state = {
    displayContent: "HomeInsurance",
    dynamicClass: "HomeInsurance",
  };

  showContents = id => {
    this.setState({
      displayContent: id,
      dynamicClass: id,
    });
  };

  render() {
    return (
      <div className="shortInsuranceSection">
        {insuranceTypes.map(banner => {
          const {
            id,
            bgImage: {className, url},
          } = banner;

          return (
            <div key={id}>
              {this.state.displayContent === id ? (
                <Banner
                  key={id}
                  bgImage={url}
                  firstText="We can assist with"
                  secondText="quick and affordable"
                  thirdText="short-term insurance"
                  customClass={className}
                ></Banner>
              ) : (
                ""
              )}
            </div>
          );
        })}
        <Container>
          <Title header="Short-term Insurance" />
          <h6 className="shortInsuranceHeader">Cover for all areas of your life</h6>
          <p className="shortInsuranceText">
            We can assist with quick and affordable short-term insurance quotes from
            reputable insurance companies across South Africa.
          </p>
        </Container>
        <ChooseInsurance
          insuranceTypes={insuranceTypes}
          legalCoverBenefits={legalCoverBenefits}
          onClick={id => this.showContents(id)}
          displayContents={this.state.displayContent}
          dynamicClass={this.state.dynamicClass}
        />
      </div>
    );
  }
}

export default Index;
