import * as React from "react";
import {Row, Col} from "react-bootstrap";

import "./OurPartners.scss";

import MiWayLogo from "../../assets/images/miwayLogo.svg";
import OnePlan from "../../assets/images/onePlan.svg";
import LegalXLogo from "../../assets/images/legalXLogo.svg";

const partners = [
  {
    image: {
      url: MiWayLogo,
    },
    alt: "miway logo",
    class: "miwayLogo",
  },
  {
    image: {
      url: OnePlan,
    },
    alt: "oneplan logo",
    class: "onePlanLogo",
  },
  {
    image: {
      url: LegalXLogo,
    },
    alt: "legalx logo",
    class: "legalXLogo",
  },
];

const OurPartners = () => {
  return (
    <Row className="partners">
      <Col md={2}>
        <h5 className="ourPartners">Our partners:</h5>
      </Col>
      {partners.map(partner => {
        return (
          <Col md={2} key={partner.class}>
            <img src={partner.image.url} alt={partner.alt} className={partner.class} />
          </Col>
        );
      })}
    </Row>
  );
};

export default OurPartners;
