import * as React from "react";
import {Row, Col, Container} from "react-bootstrap";
// import {AnimateOnChange} from "react-animation";

import "./CoverQuestions.scss";

import {coverQuestions, displaySummaries} from "../../../constants/coverQuestions";

class CoverQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      showTexts: "coverQuestions1",
      classes: [],
    };

    this.animate = this.animate.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
  }

  animate(event, id, className) {
    event.preventDefault();
    this.setState({hover: true, showTexts: id}, () => {
      document.addEventListener("hover", this.stopAnimation);
      var animateCard = document.getElementsByClassName(`${className}`);
      var i;
      for (i = 0; i < animateCard.length; i++) {
        animateCard[i].style.height = "14em";
      }
    });
  }

  stopAnimation(event, id, className) {
    event.preventDefault();
    this.setState({hover: false, classes: [], showTexts: id}, () => {
      document.removeEventListener("hover", this.animate);
      var animateCard = document.getElementsByClassName(`${className}`);
      var i;
      for (i = 0; i < animateCard.length; i++) {
        animateCard[i].style.height = "12em";
      }
    });
  }

  showTexts = async (event, id, className) => {
    event.preventDefault();
    await this.setState({showTexts: id, classes: []});
    let classes = [...this.state.classes, className];
    if (this.state.showTexts === id) {
      classes.push("boxTransition");
      this.setState({classes});
    }
  };

  render() {
    const {classes} = this.state;

    return (
      <div className="coverQSection">
        <Container>
          <div className="boxesRow">
            <Row>
              {coverQuestions.map(cover => {
                const {id, image, className, text} = cover;

                return (
                  <Col>
                    <div
                      key={id}
                      className={
                        this.state.showTexts !== id
                          ? `${className}`
                          : this.state.showTexts === "coverQuestions1"
                          ? `${className} ${classes.join(" ")}`
                          : `${classes.join(" ")}`
                      }
                      onClick={e => this.showTexts(e, id, className)}
                    >
                      <img
                        key={id}
                        src={image.url}
                        alt={image.alt}
                        className={image.class}
                      />
                      <h6 key={id} className="boxText">
                        {text}
                      </h6>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <Row>
            <div className="coverQHeaders">
              {displaySummaries.map(summary => {
                const {id, longTextOne, longTextTwo} = summary;

                return (
                  <div key={id}>
                    <h6>{this.state.showTexts === id ? longTextOne : ""}</h6>
                    <h6>{this.state.showTexts === id && longTextTwo}</h6>
                  </div>
                );
              })}
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CoverQuestions;
