import * as React from "react";
import {Row, Col} from "react-bootstrap";

import Title from "../../Title";

import "./FlexiblePayout.scss";

import PayoutIcon from "../../../assets/images/payoutIcon.svg";

const FlexiblePayout = () => {
  return (
    <div className="payoutSection" data-scroll id={"flexiblePayout"}>
      <Row>
        <Col md={3}>
          <div className="payoutRectangle">
            <img src={PayoutIcon} alt="payout icon" className="payoutImg" />
          </div>
        </Col>
        <Col md={9} className="flexBenefits">
          <Title
            header="Flexible payout options"
            customStyle={{
              textAlign: `left`,
              paddingBottom: `1em`,
              color: `var(--mainMint)`,
            }}
          />
          <ul className="benefits">
            <li>Full benefit amount paid as lump sum</li>
            <li>
              60% of benefit amount paid as lump sum + R500 Airtime + grocery vouchers for
              the remaining benefit amount paid out over 3 or 6 months{" "}
            </li>
            <li>
              R500 Airtime + grocery vouchers for the remaining benefit amount paid out
              over 3 or 6 months{" "}
            </li>
            <li>
              Monthly grocery vouchers paid out over 3 or 6 months of which the sum total
              will equal the benefit amount.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default FlexiblePayout;
