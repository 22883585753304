import * as React from "react";
import {Row, Col} from "react-bootstrap";

import Title from "../../Title";

import "./CreditLife.scss";

const CreditLife = () => {
  return (
    <div className="creditLifeSection">
      <Title header="Fin Cover Credit Life" />
      <h6 className="creditLifeHeader">
        With Fin Cover Credit Life you can protect yourself and your loved ones against
        outstanding debt.
      </h6>
      <p className="creditLifeText">
        With credit life, you can rest assured that your outstanding debt will be taken
        care of in the case of disability, retrenchment or death.
      </p>
      <div className="creditLifeInsurance">
        <Row>
          <Col md={4}>
            <div className="insRectangle">
              <h2 className="insRectangleHeader">What is credit Life insurance?</h2>
            </div>
          </Col>
          <Col md={8}>
            <div className="insParagraph">
              <p>
                Credit Life is insurance that can be taken out to cover debt on credit
                agreements that you have. With Credit life cover, any outstanding debts in
                the event of your death or permanent disability will be taken care of so
                the burden of these debts are not left with your spouse or children to
                pay.{" "}
              </p>
              <p>
                In the event of you being retrenched or temporarily disabled, Fin Cover’s
                credit life will pay up to 12 monthly personal loan instalments protecting
                you from defaulting on your financial agreements.
              </p>
            </div>
            <p className="guardRiskDisclaimer">Underwritten by Guardrisk Life (FSP 76)</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreditLife;
