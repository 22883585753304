import * as React from "react";
import {Link} from "react-router-dom";
import {Form, Col} from "react-bootstrap";

import Buttons from "../../Buttons";
import Title from "../../Title";

import "./ContactForm.scss";

import NameIcon from "../../../assets/images/nameIcon.svg";
import EmailAddress from "../../../assets/images/emailAddress.svg";
import ContactNumber from "../../../assets/images/contactNumber.svg";
import MessageIcon from "../../../assets/images/messageicon.svg";

import {partnerRef} from "../../../constants/partnerRefEnums";
import {SureContext} from "../../../context";

class ContactForm extends React.Component {
  static contextType = SureContext;

  state = {
    partnerRefId: partnerRef.CONTACTFORM,
    fullName: "",
    contactNumber: "",
    email: "",
    message: "",
    isChecked: false,
    error: null,
    submitted: false,
    formMsg: "",
  };

  handleChange = async event => {
    await this.setState({
      [event.target.name]: event.target.value,
      isChecked: event.target.checked,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    let {partnerRefId, fullName, contactNumber, email, message, isChecked} = this.state;

    // eslint-disable-next-line
    let today = new Date();
    // eslint-disable-next-line
    let dd = String(today.getDate()).padStart(2, '0');
    // eslint-disable-next-line
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // eslint-disable-next-line
    let yyyy = today.getFullYear();

    const data = {
      partnerRefId,
      consentDate: today,
      createdDate: today,
      firstName: fullName,
      cellNumber: contactNumber,
      email,
      notes: message,
      leadConsent: isChecked,
    };

    let {sendLeadsData, error} = await this.context;

    if (error) {
      this.setState({
        error,
        submitted: false,
        formMsg: "Sorry your Message has not been sent, Please try again later.",
      });
    } else {
      await sendLeadsData(data);
      this.setState({
        submitted: true,
        formMsg: "Thank you, your message has been sent.",
      });
      this.resetForm();
    }
  };

  resetForm = () => {
    const FIVE_SECONDS = 5000;
    setTimeout(async () => {
      await this.setState(prevState => ({
        fullName: "",
        contactNumber: "",
        email: "",
        message: "",
        isChecked: !prevState.isChecked,
        submitted: false,
      }));
    }, FIVE_SECONDS);
  };

  render() {
    const {
      fullName,
      email,
      contactNumber,
      isChecked,
      message,
      submitted,
      error,
      formMsg,
    } = this.state;

    return (
      <div>
        <Form
          className={submitted || error !== null ? "formSectionSubmitted" : "formSection"}
          onSubmit={this.handleSubmit}
        >
          <Title
            header="Let's chat"
            customStyle={{
              textAlign: `left`,
              paddingLeft: `0em`,
              fontSize: `1.4rem`,
            }}
          />
          <Form.Row>
            <Col xs={12} md={6}>
              <Form.Group as={Col}>
                <img src={NameIcon} alt="contact icon" className="formIconName" />
                <Form.Control
                  required
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  value={fullName}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <img src={ContactNumber} alt="contact icon" className="formIconNumber" />
                <Form.Control
                  required
                  type="number"
                  placeholder="Contact Number"
                  name="contactNumber"
                  value={contactNumber}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <img src={EmailAddress} alt="contact icon" className="formIconEmail" />
                <Form.Control
                  required
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <img src={MessageIcon} alt="message icon" className="formIconMessage" />
                <Form.Label>Your Message</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows="3"
                  className="textArea"
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <div className="form-check">
                  <input
                    required
                    type="checkbox"
                    className="form-check-input"
                    checked={isChecked}
                    onChange={this.handleChange}
                  />
                  <label title="" className="form-check-label">
                    By clicking submit, you agree to the&nbsp;
                    <Link to="/privacy-policy" className="termsUrl">
                      terms and conditions.
                    </Link>
                  </label>
                </div>
              </Form.Group>
              <Buttons type="submit" customStyle="formButton" title="Submit" />
            </Col>
          </Form.Row>
        </Form>
        {submitted || error ? (
          <div className="submitSuccess">
            <p className="submitText">{formMsg}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ContactForm;
