import * as React from "react";
import {Row, Col} from "react-bootstrap";

import Title from "../Title";

import "./WhyNifty.scss";

import HeartIcon from "../../assets/images/heartIcon.svg";
import ControlIcon from "../../assets/images/controlIcon.svg";
import EffortlessIcon from "../../assets/images/effortlessIcon.svg";

const WhyNifty = () => {
  return (
    <div className="whyGetSureSection">
      <Row>
        <Col>
          <Title
            header="Why Fin Cover"
            customStyle={{
              textAlign: `center`,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="contentSection">
            <img src={HeartIcon} alt="heart icon" className="heartImg" />
            <h6 className="contentTitle">Flexible & affordable funeral cover</h6>
            <p className="contentText">
              Choose a funeral plan to suit you and your family, whether big or small.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="contentSection">
            <img src={EffortlessIcon} alt="effortless" className="effortImg" />
            <h6 className="contentTitle">Effortless application</h6>
            <p className="contentText">
              Get covered from the comfort of your own home. Our systems are completely
              online.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="contentSection">
            <img src={ControlIcon} alt="control" className="controlImg" />
            <h6 className="contentTitle">You are in control</h6>
            <p className="contentText">
              Our paperless system gives you the control to make changes to your profile
              or claim from anywhere, anytime.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WhyNifty;
