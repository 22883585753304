//eslint-disable-line
/* eslint-disable-next-line */
/* eslint-disable */

export const funeralQuestions = [
  {
    id: "funeralQuestions1",
    title: "General",
    questions: [
      {
        id: "funeralSubQuestions1",
        subTitle: "How do I pay my premiums?",
        text:
          'Payments can be made via debit order. <br/><br/>A special deduction can be arranged on request by sending a mail to <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a> or by contacting us during business hours on 012 045 0604',
      },
      {
        id: "funeralSubQuestions2",
        subTitle: "What happens if a premium fails?",
        text:
          "Should you miss the first instalment, the policy will go into a “Not taken up” (NTU) status and a new policy application will need to be completed.<br/><br/>If the policy is already active, the policy will go into arrears and will receive a pending status until the arears amount has been paid.<br/><br/>The policy will lapse if two consecutive instalments are missed which will deem the policy null and void should you need to claim.",
      },
      {
        id: "funeralSubQuestions3",
        subTitle: "When does the policy lapse?",
        text: "After two payments have been missed.",
      },
      {
        id: "funeralSubQuestions4",
        subTitle: 'What does "lapse" mean?',
        text: "The policy is no longer active and unfortunately no claims will be paid.",
      },
      {
        id: "funeralSubQuestions5",
        subTitle: "Can I reinstate the policy after it has lapsed?",
        text:
          "If the main member wishes to reinstate the policy within 6 (six) months of the policy lapsing, he/she may do so, subject to new waiting periods. For the main member, spouse, and children, this will be 2 (two) months if the waiting period of 4 (four) months of the initial policy was fully completed.<br/><br/>For parents, the new waiting period will be 4 (four) months, provided that the initial waiting period of 12 (twelve) months was fully completed.<br/><br/>For purposes of this Benefit, a claim for natural death cannot be lodged within the first 4 (four) months from the Entry Date for the Main Member, Children and Spouse, and 6 (six) months for Parents and Parents in law (the “Waiting Period”). There is no waiting period for any unnatural death; however, the first premium must have been paid in order for cover to be in place.",
      },
    ],
  },
  {
    id: "funeralClaimsQuestions1",
    title: "Funeral Claims",
    questions: [
      {
        id: "funeralSubQuestions1",
        subTitle: "I want to claim on my policy, what documents will I need?",
        text:
          'Log onto our website <a href="www.cover.fin.africa" target="_blank" class="faqAnchor">www.cover.fin.africa</a> and enjoy the convenience of self service or contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "funeralSubQuestions2",
        subTitle: "How long does it take to pay-out a claim?",
        text: "Within 48 hours of approval of the claim.",
      },
      {
        id: "funeralSubQuestions3",
        subTitle: "Who approves the claim?",
        text:
          "NiftyCover (PTY) LTD will approve the claim however, ex gratia payments need to be approved by the underwriter - Guardrisk life limited (FSP license number 76)",
      },
      {
        id: "funeralSubQuestions4",
        subTitle: "What happens if my claim is rejected?",
        text:
          "We will inform you telephonically and/or by e-mail of the status and reason. You will also receive a letter from the provider that will explain the process to follow should you be dissatisfied with the underwriter's decision.",
      },
      {
        id: "funeralSubQuestions5",
        subTitle: "What happens if my claim is approved?",
        text:
          "We will inform the you or the claimant by telephone or e-mail of the approval, and will advise when the payment will be made.",
      },
      {
        id: "funeralSubQuestions6",
        subTitle: "Who will the claim amount be paid to?",
        text: "The beneficiary nominated by the main member",
      },
    ],
  },
  {
    id: "policyAmendmentQuestions1",
    title: "Policy amendments",
    questions: [
      {
        id: "policyAmendmentSubQuestions1",
        subTitle: "I want to make a change to my policy, what must I do?",
        text:
          'Log onto our website cover.fin.africa and enjoy the convenience of self service or contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "policyAmendmentSubQuestions2",
        subTitle: "I need to change the banking details, what must I do?",
        text:
          'Log onto our website cover.fin.africa and enjoy the convenience of self service or contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "policyAmendmentSubQuestions3",
        subTitle: "Can I make a special deduction?",
        text:
          'Yes. Please contact us on 012 045 0604 during office hours or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a> to discuss available options',
      },
      {
        id: "policyAmendmentSubQuestions4",
        subTitle: "I want to change my cover amount, what must I do?",
        text:
          'Log onto our website cover.fin.africa and enjoy the convenience of self service or contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "policyAmendmentSubQuestions5",
        subTitle: "If I want to add/remove a policy member, what must I do?",
        text:
          'Log onto our website cover.fin.africa and enjoy the convenience of self service or contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "policyAmendmentSubQuestions6",
        subTitle: "How do I cancel a policy?",
        text:
          'Please contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a>',
      },
      {
        id: "policyAmendmentSubQuestions7",
        subTitle: "If I want a refund, what must I do?",
        text:
          'Please contact us on 012 045 0604 or <a href="mailto:coverinfoza@fin.africa" target="_blank" class="faqAnchor">coverinfoza@fin.africa</a> with your refund request.',
      },
    ],
  },
];

export const creditLifeQuestions = [
  {
    id: "creditLifeQuestions1",
    questions: [
      {
        id: "creditLifeQuestions1",
        subTitle: "What does your Credit Life policy cover?",
        text: `
            Death: An amount equal to, but not exceeding, the Outstanding Loan Amount will be paid to the Credit Provider if You pass away from either natural or unnatural causes.
            <br/>
            <br/>
            Retrenchment: An amount equal to Your monthly instalment under the Credit Agreement will be paid to the Credit Provider:
            <br/>
            <br/>
            <ul class="creditLifeList">
              <li>for a period of 12 (twelve) months;</li>
              <li>
                for the remaining repayment period of the Credit Agreement; or
              </li>
              <li>until You find employment or are able to earn an income.</li>
            </ul>
            <br/>
            The benefit is payable for whichever is the shorter period.
            <br/>
            <br/>
            Permanent disability: An amount equal to, but not exceeding, the Outstanding Loan Amount will be paid to the Credit Provider if You suffer a Total Permanent Disability
            <br/>
            <br/>
            Temporary disability: If You suffer from a Temporary Disability for a period in excess of 31 (thirty-one) consecutive days, an amount equal to Your monthly instalment under the Credit Agreement will be paid to the Credit Provider:
            <br/>
            <br/>
            <ul class="creditLifeList">
              <li>for a period of 12 (twelve) months;</li>
              <li>
                for the remaining repayment period of the Credit Agreement; or
              </li>
              <li>until You are no longer disabled.</li>
            </ul>
            <br/>
            The benefit is payable for whichever is the shorter period.
        `,
      },
      {
        id: "creditLifeQuestions2",
        subTitle: "How do I pay my premiums?",
        text: "Your premium will form part of your loan repayment.",
      },
      {
        id: "creditLifeQuestions3",
        subTitle: "What happens if a premium fails?",
        text:
          "You will receive a lapse warning, should you miss the next months installment your policy will lapse",
      },
      {
        id: "creditLifeQuestions4",
        subTitle: "When does the policy lapse?",
        text: "After two payments have been missed.",
      },
      {
        id: "creditLifeQuestions5",
        subTitle: 'What does "lapse" mean?',
        text: "The policy is no longer active and unfortunately no claims will be paid.",
      },
      {
        id: "creditLifeQuestions6",
        subTitle: "What is a waiting period?",
        text:
          "The waiting period refers to a period in which cover will not be granted. Should a claim arise during this period, no claim shall be payable.",
      },
      {
        id: "creditLifeQuestions7",
        subTitle: "How long is the waiting period on this policy?",
        text:
          "12 months from the Entry Date for Suicide or self-inflicted injury<br/><br/>6 Months for any pre-existing condition for which you received treatment or advice from a physician within the 12 months preceding the entry ate",
      },
      {
        id: "creditLifeQuestions8",
        subTitle: "Can the policy be cancelled?",
        text:
          "You have the right to cancel this Policy at any time by giving 1 calendar months' notice of the intention to cancel. Such cancellation, after the initial 31 (thirty-one) days from the Entry Date, will not attract a refund of any Premiums paid.<br/><br/>Please also contact the Credit Provider (us), as You need to provide alternative cover that meets the Credit Provider’s requirements. If the alternate policy does not meet the requirements, we have the right to refuse cancellation. Please refer to Your Credit Agreement for requirements during the loan term.",
      },
      {
        id: "creditLifeQuestions9",
        subTitle: "Where can I get more details or information?",
        text:
          'Should you need any assistance with your policy or need any more information you can call 012 045 0604 during working hours within the week Alternatively you can email <a href="mailto:coverinfoza@fin.africa" target="_blank">coverinfoza@fin.africa</a> and a consultant will get back to you as soon as possible.',
      },
      {
        id: "creditLifeQuestions10",
        subTitle: "Who is the underwriter of the policy",
        text: "Guardrisk life limited (FSP license number 76)",
      },
      {
        id: "creditLifeQuestions11",
        subTitle: "Where can I lodge a complaint or issue?",
        text:
          'Complaints can be directed to <a href="mailto:coverinfoza@fin.africa" target="_blank">coverinfoza@fin.africa</a>',
      },
      {
        id: "creditLifeQuestions12",
        subTitle: "When does the policy start/commence?",
        text: "As soon as your loan has been disbursed.",
      },
      {
        id: "creditLifeQuestions13",
        subTitle: "When do I get cover?",
        text: "As soon as your policy commences",
      },
      {
        id: "creditLifeQuestions14",
        subTitle: "I want to claim on my policy, what documents will I need?",
        text:
          'You will be notified of the various documents required once your claim has been reported depending on the type of event. Claims can be sent to <a href="mailto:coverinfoza@fin.africa" target="_blank">coverinfoza@fin.africa</a>',
      },
      {
        id: "creditLifeQuestions15",
        subTitle: "Who approves the claim?",
        text:
          "NiftyCover (PTY) LTD will approve the claim however, ex gratia payments need to be approved by the underwriter - Guardrisk life limited (FSP license number 76)",
      },
      {
        id: "creditLifeQuestions16",
        subTitle: "What happens if my claim is rejected?",
        text:
          "We will inform you telephonically and/or by e-mail of the status and reason. You will also receive a letter from the provider that will explain the process to follow should you be dissatisfied with the underwriter's decision.",
      },
      {
        id: "creditLifeQuestions17",
        subTitle: "What happens if my claim is approved?",
        text:
          "Payments will be made to the credit provider covering the loan in line with the event that occurred and the benefits that are covered.",
      },
    ],
  },
];
