import React, {useState, Suspense, lazy} from "react";
import {Container} from "react-bootstrap";

import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

import BannerImg from "../assets/images/newsBanner.jpg";

import "./index.scss";

const SingleNews = lazy(() => import("../components/News/SingleNews"));
const Banner = lazy(() => import("../components/Global/Banner"));

const SingleNewsPage = props => {
  const [childData, setChildData] = useState("");

  let {articleImgUrl} = childData;
  let newImageUrl = articleImgUrl;
  let newUrl = newImageUrl ? newImageUrl.substr(2) : "";
  let imgFormat = newImageUrl ? newImageUrl.slice(-3) : "";
  let title = childData ? childData.title : "";

  return (
    <div>
      <MetaData
        title={title}
        image={newUrl}
        description={props.match.params.id}
        imageFormat={imgFormat}
      />
      <SEO
        title={title}
        image={newUrl}
        description={props.match.params.id}
        imageFormat={imgFormat}
      />
      <Suspense fallback={<h1>{/*Blank*/}</h1>}>
        <Banner
          bgImage={BannerImg}
          firstText="Keep up to date"
          secondText="with the latest news"
          customClass="singleNewsBannerStyle"
        ></Banner>
      </Suspense>
      <Suspense fallback={<h1>{/*Blank*/}</h1>}>
        <Container>
          <SingleNews passChildData={setChildData} />
        </Container>
      </Suspense>
    </div>
  );
};

export default SingleNewsPage;
