import * as React from "react";
import {Container} from "react-bootstrap";

import NavBar from "./NavBar";

import "./Header.scss";

import config from "../../constants/configs";

const Header = () => {
  return (
    <Container fluid className="headerSection">
      <span className="getSureDeets">
        <a
          href={`${"mailto:"}${config.NIFTYCOVER_CONTACT_EMAIL}`}
          title="coverinfoza@fin.africa"
          className="getSureInfo"
        >
          {config.NIFTYCOVER_CONTACT_EMAIL} |&nbsp;
        </a>
        <a
          href={`${"tel:"}${config.NIFTYCOVER_CONTACT_NUMBER}`}
          title={config.NIFTYCOVER_CONTACT_NUMBER}
          className="getSureInfo"
        >
          {config.NIFTYCOVER_CONTACT_NUMBER}
        </a>
      </span>
      <br />
      <div className="headerContainer">
        <NavBar />
      </div>
    </Container>
  );
};

export default Header;
