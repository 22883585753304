import React from "react";
import {Container} from "react-bootstrap";

import Banner from "../components/Global/Banner";
import CreditLife from "../components/Products/CreditLife/CreditLife";
import SEO from "../components/SEO";
import MetaData from "../components/MetaData";

import "./index.scss";

import CreditLifeBanner from "../assets/images/slide2.jpg";

const CreditLifes = () => {
  return (
    <div>
      <MetaData title="Credit Life" />
      <SEO title="Credit Life" />
      <Banner
        bgImage={CreditLifeBanner}
        firstText="Protect yourself and"
        secondText=" your loved ones from"
        thirdText="outstanding debt."
        customClass="creditBannerStyle"
      ></Banner>
      <Container>
        <CreditLife />
      </Container>
    </div>
  );
};

export default CreditLifes;
