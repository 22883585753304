import React from "react";

import "./ContentsSection.scss";

class ContentsSection extends React.Component {
  render() {
    return (
      <section
        className={`${"wrapper" +
          // eslint-disable-next-line
          (this.props.bgImage ? " " + "sectionImageBackground" : "") +
          " " +
          (this.props.customClass || "")}`}
        style={Object.assign(
          {backgroundImage: `url(${this.props.bgImage})`},
          this.props.customStyle || {}
        )}
      >
        <div className="contentsText">
          <h2 className="firstText">{this.props.firstText}</h2>
          <h2 className="secondText">{this.props.secondText}</h2>
          <h2 className="thirdText">{this.props.thirdText}</h2>
        </div>
        <div>{this.props.children}</div>
      </section>
    );
  }
}

export default ContentsSection;
